import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
//import CardPartial from './cardPartial';
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image';
import $ from 'jquery';
import OrderPage from "../Admin/Users/OrderPage";
//import PaymentForm from "../PaymentForm";
//import UpdatedTerms from "../Register/UpdatedTerms";
import CardFront from "../Summary/CardFront";
import CardBack from "../Summary/CardBack";

class OrderCardsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            shortname: '',
            fullName: '',
            account_info: '',
            group_numbers: [],
            group_status: '',
            GroupData: '',
            selected_group: '',
            isPrimary: '',
            show_Payment_popup: false,
            show_Userdetail_popup: false
        }
        this.printAll = this.printAll.bind(this);
        this.downloaddocall = this.downloaddocall.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.toggle_user_detail = this.toggle_user_detail.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.selectedGroupData = this.selectedGroupData.bind(this)
        this.printIndivisual = this.printIndivisual.bind(this);
        this.downloaddoc = this.downloaddoc.bind(this);
    }

    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
            this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
            let userinfo = window.atob(localStorage.getItem('_file_info'));
            this.setState({ account_info: userinfo });
            this.GetUserGroups(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
        }
        catch {
            this.logout();
        }
    }
    toggle_user_detail = (e) => {
        $('body').removeClass('payment-popup-active');
        e.stopPropagation();
        this.setState({ show_Userdetail_popup: !this.state.show_Userdetail_popup });
        this.setState({ show_Payment_popup: !this.state.show_Payment_popup });
    }

    handleClick = (e) => {
        this.setState({ show_Payment_popup: true });
        $('body').addClass('payment-popup-active');
    }
    printAll(event) {

        //var originalContents = document.body.innerHTML;
        // document.body.innerHTML = printableElements;
        //window.print();
        //document.body.innerHTML = originalContents;
        window.print()
        window.location.reload()
    }
    downloaddocall(event) {
        let ids = [];
        //var pdf;Commented because not in use
        $('.contentarea .content_sec .card_page_content .cards').each(function () {
            let idattr = $(this).attr('id');
            ids.push(idattr)
        })
        ids.forEach(function (key, idx, array) {
            domtoimage.toPng(document.getElementById(key))
                .then(function (blob) {
                    var pdf = new jsPDF("l", "mm", "a4");
                    var width = pdf.internal.pageSize.getWidth();
                    var height = pdf.internal.pageSize.getHeight();
                    if (window.screen.width <= 1024) {
                        pdf.addImage(blob, 'JPEG', 10, 10, width / 1.5, height - 10);
                    } else if (window.screen.width <= 575) {
                        pdf.addImage(blob, 'JPEG', 10, 10, width / 2, height - 10);
                    } else {
                        pdf.addImage(blob, 'JPEG', 10, 10, width - 20, height / 2);
                    }
                    setTimeout(() => {
                        pdf.save(key + '.pdf');
                    }, 1500)

                });
        });
    }
    GetUserGroups(UserId, UserName) {
        userService.GetUserGroups(UserId, UserName)
            .then(
                apidata => {
                    this.setState({ group_numbers: apidata, selected_group: apidata[0].groupnumber, group_status: apidata[0].status, isPrimary: apidata[0].isPrimary })
                    this.selectedGroupData(apidata[0].groupnumber)
                }
            );
    }

    async updateGroup(e) {
        let group_no = e.target.value
        await this.selectedGroupData(group_no)
        this.setState({ selected_group: group_no })
    }
    selectedGroupData(seldata) {
        let sgd = this.state.group_numbers.filter((item) => {
            return item.groupnumber === seldata ? item : null
        })
        this.setState({ GroupData: sgd[0], isPrimary: sgd[0].isPrimary })
    }
    printIndivisual(event, data) {
        var printableElements = document.getElementById(event.target.parentElement.nextElementSibling.getAttribute('id')).outerHTML;
        document.body.innerHTML = printableElements;
        setTimeout(() => {
            window.print();
            window.location.reload()
        }, 3000)
    }
    downloaddoc(id, name, event) {
        domtoimage.toPng(document.getElementById(id))
            .then(function (blob) {
                var doc = new jsPDF("l", "mm", "a4");
                var width = doc.internal.pageSize.getWidth();
                var height = doc.internal.pageSize.getHeight();
                if (window.screen.width <= 1024 && window.screen.width > 575) {
                    doc.addImage(blob, 'JPEG', 10, 10, width / 1.5, height - 10);
                } else if (window.screen.width <= 575) {
                    doc.addImage(blob, 'JPEG', 10, 10, width / 2.2, height);
                } else {
                    doc.addImage(blob, 'JPEG', 10, 10, width - 20, height / 2.2);
                }
                doc.save(name + '.pdf');
            });
    }
 
    render() {
        let { group_numbers } = this.state;
        let userRole = window.atob(localStorage.getItem('UserRole'));
        let cardsData = [
            {
                cardname: 'Glic Card',
                classnames: 'glic_card',
                bin: '023518',
                pcn: 'ARX',
            }];
        if (this.state.group_status === 'Yes') {
            cardsData = [{
                cardname: 'Glic Card',
                classnames: 'glic_card',
                bin: '023518',
                pcn: 'ARX',
            },
            {
                cardname: 'Citizen Card',
                classnames: 'citizen_card',
                bin: '015284',
                pcn: 'CRX'
            },
            //{
            //    cardname: 'Walmart Card',
            //    classnames: 'walmart_card',
            //    bin: '610396',
            //    pcn: 'WM1'
            //},
            {
                cardname: 'NVT Card',
                classnames: 'nvt_card',
                bin: '610602',
                pcn: 'NVT'
            },
            {
                cardname: 'CVS Card',
                classnames: 'cvs_card',
                bin: '610398',
                pcn: 'CV1'
            },
            {
                cardname: 'DST Card',
                classnames: 'dst_card',
                bin: '600428',
                pcn: 'HRX'
            }];
        }
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        /* if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) != true && this.state.account_info != 0) return <Redirect to="/account" />*/
        localStorage.setItem('pagename', 'ordercard');
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <div>
                {
                    (this.state.selected_group === null || this.state.selected_group === "" || this.state.selected_group === undefined) ?
                        null :
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="4" account_info={this.state.account_info} fullName={this.state.fullName} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="Card_Page_header">
                                        <div className="h2_title">
                                            <h2>Order Cards</h2>
                                            <p>The cards below are your personal cards to share.</p>
                                        </div>
                                        <div className="buttons_area AddPayment">
                                            <div className="button" onClick={this.downloaddocall.bind(this)}>Download All</div>
                                            <div className="button" onClick={this.printAll.bind(this)}>Print All</div>
                                            <div className="button red"><a href="https://www.rxhacker.supply/" target="_blank">Order Printed Cards</a></div>
                                            {/*<div className="button red" onClick={this.handleClick.bind(this)}>Get Your Starter Kit</div>*/}
                                        </div>
                                    </div>
                                    <div className="card_page_content">
                                        <div className="all_cards">
                                            <div className="select_group">
                                                <select onChange={this.updateGroup} value={this.state.selected_group} className="tt_select">
                                                    {group_numbers.map((card, index) =>
                                                        <option key={index} value={card.groupnumber}>{card.groupnumber}</option>
                                                    )}
                                                </select>
                                            </div>
                                            {
                                                cardsData.map((card, index) => {
                                                    let mId;
                                                    let datetime = Math.floor(Math.random() * 1000000);
                                                    let userGroup = this.state.GroupData.groupnumber;
                                                    if (card && card.classnames === 'cvs_card') {
                                                        mId = "AX" + datetime;
                                                    } else if (card && card.classnames === 'walmart_card') {
                                                        mId = "AX" + datetime;
                                                    } else {
                                                        mId = userRole === 'consumer' ? '100-4542' : "AX" + datetime;
                                                    }
                                                    card.mId = mId;
                                                    return (
                                                        <div key={index}>
                                                            <h3>{card.cardname}</h3>
                                                            <div className="buttons">
                                                                <div className="download" onClick={this.downloaddoc.bind(this, card.pcn + "_" + this.state.GroupData.groupnumber + index, card.pcn + "_" + this.state.GroupData.groupnumber)}></div>
                                                                <div className="print" onClick={this.printIndivisual.bind(this)}></div>
                                                            </div>
                                                            <div className={'cards ' + card.classnames} id={card.pcn + "_" + this.state.GroupData.groupnumber + index}>
                                                                <CardFront cardData={card} groupData={this.state.GroupData} />
                                                                <CardBack cardData={card} groupData={this.state.GroupData} groupNumber={userGroup} />
                                                            </div>
                                                            <div class={cardsData.length - 1 === index ? 'pagebreak hide' : 'pagebreak'}> </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/*<div className="card_page_content">
                                    {group_numbers.map((card, index) =>
                                        <CardPartial key={index}  {...card}/>
                                    )}
                                </div>*/}
                                    <div>
                                        <div className="Account_info_form_parent">
                                            {this.state.show_Payment_popup === true ? (
                                                <div className="resetgroup_box">
                                                    <OrderPage toggle_user_detail={this.toggle_user_detail.bind(this)} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default OrderCardsPage;