import React, { Component } from 'react';
import $ from 'jquery';
import close_btn from '../../images/close_btn.svg';

export class CtPopup extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
    }

    render() {
        return (
            <div className="ct_popup">
                <div>
                    <div className="header">
                        {this.props.title}
                        <div className="close" onClick={this.props.toggle_reset_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body">
                        <div className="commision_tier">
                            <div className="ct_title_box">
                                <span className="ct_title"><img src={this.props.ct_tierData.tierIcon} className="ct_icon" alt=""/> {this.props.ct_info.tierName}</span>
                            </div>
                            <div className="ct_progress">
                                <div className="progress" onMouseOver={() => this.props.settooltipPositioning(true)}>
                                    <div className="progress-bar" style={{ width: this.props.ct_percent + '%' }}></div>
                                </div>
                                <div className="progress-tooltip" id="progress-tooltippop" style={{ left: 'calc(' + this.props.ct_percent + '% - 12px)' }}><span>{this.props.ct_info.claims} Transaction{this.props.ct_info.claims > 1 ? 's' : ''}</span> </div>
                            </div>
                            <div className="ct_minmax">
                                <span className="min_value">{this.props.ct_tierData.from}</span>
                                <span className="min_value">{this.props.ct_tierData.to}</span>
                            </div>
                            <p>{this.props.ct_tierData.to - this.props.ct_info.claims + 1} Transaction{this.props.ct_info.claims > 1 ? 's' : ''} to reach the next level</p>
                        </div>
                        <p>The NuLifeSpan Rx RockStar Revenue Program gives you the opportunity to scale up your commission earnings as you produce more claims! The table below provides all of the details.</p>
                        <h3>How it works</h3>
                        <div className="tabular_box">
                            <div className="table_header">
                                <div>TIER NAME</div>
                                <div>NO. OF TRANSACTION </div>
                                <div>COMMISSION ($)</div>
                            </div>
                            <div className="table_body">
                                {this.props.ct_info.tierJson.map((tdata) =>
                                    <div className="table_row">
                                        <div>
                                            <div className="ct_tiername"><img src={tdata.tierIcon} className="ct_icon" alt=""/> {tdata.tierName}</div>
                                            <div>{tdata.from} - {tdata.to}</div>
                                            <div>${tdata.commission}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
