import React, { Component } from "react";
import app_store from '../../images/app_store.svg';
import play_store from '../../images/play_store.svg';
import cvs from '../../images/cvs-card.png';
import wg from '../../images/walgreens.png';
import wmcvs from '../../images/wallmart-cvs.png';
import ld from '../../images/longs-drugs.png';
import ra from '../../images/rite-aid.png';
import kroger from '../../images/kroger.png';
import cvsdot from '../../images/cvs-dot.png';
import wallmart from '../../images/wallmart-card.png';
import AppleWallet from "../../images/apple-wallet.svg";
import GoogleWallet from "../../images/google-wallet.svg";
import AppleModal from "../../components/Consumer/appleModal";
import Resources from "../../resources.json";
import { userService } from "../../_services";

class CardBack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carddata: {},
            groupdata: '',
            passtype: 'generic',
            deviceName: '',
            browserName: '',
            applePopup: false,
            shwoApplePopup: false,
            Primary_Gn: this.props.PrimaryGn,
            applePass: '',
            googlePass: '',
            popupApplePass: '',
            groupNumber: '',
            linkDisabled: false
        };
        this.appleWalletPopup = this.appleWalletPopup.bind(this);
    }
    componentDidMount() {
        try {
            // get user device and browser name 
            let userAgent = window.navigator.userAgent;
            let devicetype = this.GetUserAgent(userAgent);
            let browsername = this.GetBrowserName(userAgent);
            //setTimeout(() => {
            //    this.wallet();
            //}, 100);
            if (devicetype == "iPhone" && browsername != "Safari") {
                this.setState({ deviceName: devicetype, browserName: browsername, shwoApplePopup: true });
            }
            else {
                this.setState({ deviceName: devicetype, browserName: browsername });
            }

        }
        catch {
        }
    }
    componentWillReceiveProps(props) {
        this.state.carddata = props.cardData ? props.cardData : { bin: '', pcn: '' }
        this.state.groupdata = props.groupData ? props.groupData : { groupnumber: '' }
        //setTimeout(() => {
        //    this.wallet();
        //}, 100);
    }
    wallet() {
        try {
            if (this.state.carddata.classnames != 'cvs_card' && this.state.carddata.classnames != 'walmart_card') {
                this.Apple_Google_Pass_Click('apple');
                this.Apple_Google_Pass_Click('google');
            }
        }
        catch (error) {
        }
    }
    appleGooglePassClick(passtype) {
        try {   
            this.setState({ linkDisabled: true });
            let bin = this.props.cardData.bin;
            let pcn = this.props.cardData.pcn;
            let memberid = this.props.cardData.mId;
            let appname = Resources.ApplicationResources.pass[0].appname;
            let requestURL = '';
            requestURL = passtype === 'apple' ? Resources.ApplicationResources.pass[0].applelink : Resources.ApplicationResources.pass[0].googlelink;
            userService.apple_google_wallet_pass_link(requestURL, bin, pcn, this.props.groupNumber, memberid, this.state.passtype, appname)
                .then(
                    apidata => {
                        if (passtype === 'apple') {
                            window.open(apidata, '_blank');
                        } else {
                            this.setState({ linkDisabled: false });
                            window.open(apidata, '_blank');
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    applepass_maillink(passtype) {
        try {
            this.setState({ linkDisabled: true });
            let bin = this.props.cardData.bin;
            let pcn = this.props.cardData.pcn;
            let memberid = this.props.cardData.mId;
            let appname = Resources.ApplicationResources.pass[0].appname;
            let requestURL = '';
            requestURL = passtype === 'apple' ? Resources.ApplicationResources.pass[0].applelink : Resources.ApplicationResources.pass[0].googlelink;
            userService.apple_google_wallet_pass_link(requestURL, bin, pcn, this.props.groupNumber, memberid, this.state.passtype, appname)
                .then(
                    apidata => {
                        if (passtype === 'apple') {
                            this.setState({ popupApplePass: apidata });
                        } else {
                            this.setState({ googlePass: apidata });
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    GetUserAgent(UserAgent_Raw) {
        if (/Mobi|Android/i.test(UserAgent_Raw)) {
            if (/iPhone|iPad|iPod/i.test(UserAgent_Raw)) {
                return 'iPhone';
            }
            else {
                return 'Android';
            }
        } else {
            return 'Desktop';
        }
    }
    GetBrowserName(UserAgent_Raw) {
        if (/Firefox/i.test(UserAgent_Raw)) {
            return 'Firefox';
        } else if (/Chrome/i.test(UserAgent_Raw)) {
            return 'Chrome';
        } else if (/Safari/i.test(UserAgent_Raw)) {
            return 'Safari';
        } else if (/MSIE|Trident/i.test(UserAgent_Raw)) {
            return 'Internet Explorer';
        } else if (/Edge/i.test(UserAgent_Raw)) {
            return 'Edge';
        } else if (/Opera|OPR/i.test(UserAgent_Raw)) {
            return 'Opera';
        } else if (/Brave/i.test(UserAgent_Raw)) {
            return 'Brave';
        } else if (/Vivaldi/i.test(UserAgent_Raw)) {
            return 'Vivaldi';
        } else if (/Yandex/i.test(UserAgent_Raw)) {
            return 'Yandex';
        } else if (/Chromium/i.test(UserAgent_Raw)) {
            return 'Chromium';
        } else {
            return 'Unknown';
        }
    }
    appleWalletPopup() {
        if (this.state.deviceName == "iPhone" && this.state.browserName != "Safari") {
             this.applepass_maillink('apple');
            this.setState({
                applePopup: !this.state.applePopup
            });
        }
        else {
            this.appleGooglePassClick('apple');
        }
    }
    openAppleLink() {
        this.appleGooglePassClick('apple');
    }
    openGooglePass() {
        try {
            this.appleGooglePassClick('google');
        }
        catch (error) {
        }
    }
    render() {
        let deviceName = this.state.deviceName;
        let browserName = this.state.browserName;
        let showAppleWallet = true;
        let showGoggleWallet = true;
        if (deviceName == 'Android') {
            showAppleWallet = false;
        } else if (deviceName == 'iPhone') {
            showGoggleWallet = false;
        }
        return (
            <>
                {
                    this.state.carddata && this.state.carddata.classnames === 'cvs_card' ?
                        <>
                            <div className="order_card card_back glic_card">
                                <div className="card_body">
                                    <h3>Exclusive savings at CVS Pharmacy&#174; locations<br />on prescription medications. </h3>
                                    <div className="instructions">
                                        <p><strong>Customer Instructions:</strong> Show this card to your CVS pharmacist to receive savings. For more information, call toll-free to 1-866-978-1084.</p>
                                        <p><strong>Pharmacist Instructions:</strong> This card entitles the cardholder to all prescription benefits associated with the BIN, GRP, and PCN codes. For help processing, please call toll-free at 1-800-974-3135. </p>
                                    </div>
                                    <h4>This card is accepted at <img src={wmcvs} alt="Walmart CVS" /> <img src={cvsdot} alt="CVS Pharmacy" /> <img src={ld} alt="Longs Drugs" /></h4>
                                    <p>Visit <a href="https://singlecare.com/honestdiscounts" target="_blank" style={{ color: '#ce0c2e' }}>singlecare.com/honestdiscounts</a> for more information, pricing and participating pharmacies.</p>
                                    <p>Your privacy is protected. We do not rent or sell your personal information.<br />By using this card you have agreed to the terms and conditions found at singlecare.com/terms-and-conditions. The CVS Pharmacy logo is a trademark and/or registered trademark of CVS Pharmacy and/or one of its affiliates.</p>
                                    <div className="seperator-text"><span>This card is free. This is not insurance</span></div>
                                </div>
                                <div className="card-footer">
                                    <div className="right_info">
                                        <span>&copy; 2019 SingleCare Administrators</span>
                                        <span>SCHD_0819_CVS_CA</span>
                                    </div>
                                    <div className="instructions">
                                        <h4>Pharmacist Instructions</h4> <p>This card is preactivated and gives member discounts through the BIN. PCN, GROUP on the front If you need assistance, call 811-800-7820</p>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <>{this.state.carddata && this.state.carddata.classnames === 'walmart_card' ?
                            <>
                                <div className="order_card card_back glic_card">
                                    <div className="card_body">
                                        <h3>Available savings at Walmart pharmacies<br />on FDA-approved prescription medications.*</h3>
                                        <div className="instructions">
                                            <p><strong>Customer Instructions:</strong> Show this card to your Walmart pharmacist to receive savings. For more information, call toll-free at 1-866-978-1091.</p>
                                            <p><strong>Pharmacist Instructions:</strong> This card entitles the cardholder to all prescription benefits associated with the BIN, GRP, and PCN codes. For help processing, please call toll-free at 1-800-974-3135.</p>
                                        </div>
                                        <h4>This card is accepted at <img src={wallmart} alt="Walmart" style={{ maxHeight: '15px', marginBottom: '-3.5px' }} /></h4>
                                        <p>Visit <a href="https://singlecare.com/honestdiscounts" target="_blank" style={{ color: '#1e76d0' }}>singlecare.com/honestdiscounts</a> for more information, pricing and participating pharmacies.</p>
                                        <p>This card is not insurance.<br />Your privacy is protected. We do not rent or sell your personal information.<br />By using this card you have agreed to the terms and conditions found at singlecare.com/terms-and-conditions.</p>
                                        <div className="walmart_info">
                                            <span>*Subject to pharmacist discretion</span>
                                            <span>&copy; 2019 SingleCare Administrators</span>
                                            <span>SCHD_0819_WM_CA</span>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="order_card card_back glic_card">
                                    <div className="card_body">
                                        <div className="left_info">
                                            <div className="col-6">
                                                <strong>Member Instructions</strong><br />
                                                1. Present card to your pharmacist.<br />
                                                2. Ask for discount on each prescription<br />
                                                3. SAVE UP TO 80%*<br />
                                                4. Use over and over! This card does not expire
                                            </div>
                                            <div className="col-6">
                                                <strong>Instrucciones Miembros</strong><br />
                                                1. Presente la tarjeta su farmaceutico<br />
                                                2. Pedir descuento en cada recata<br />
                                                3. AHORRE HASTA UN 80%**<br />
                                                4. jUtilice una y otra vez! Esta tarjeta no caduca.
                                            </div>
                                            <div className="col-12">
                                                <p>*Prescription savings vary by prescription and by pharmacy, and may reach up to 80% off of the cash price. **Los ahorros en recetas varian segun la receta y la farmacia, y pueden alcanzar hasta un 80% de descuento sobre el precio en efectivo.</p>
                                                <p>Visit nulifespanrx.com, download the NuLifeSpan Rx app, or call 800-984-4031 for more information, to report any concerns and to find participating pharmacies near you! The NuLifeSpan Rx Program, prices, list of covered drugs, and participating pharmacies are subject to change. By using this card, you have agreed to the terms and conditions found at NuLifeSpan Rx.com/terms. The NuLifeSpan Rx Program is provided by NuLifeSpan Rx LLC, PO Box 1703, Allen, TX 75013.</p>
                                            </div>
                                            <div className="seperator-text"><span>This card is free. This is not insurance</span></div>
                                        </div>
                                    </div>
                                    
                                        <div className="card-footer">
                                            <div className="logos">
                                                <img src={cvs} alt="CVS Pharmacy" />
                                                <img src={wg} alt="Wallgreens" />
                                                <img src={ra} alt="Rite Aid" />
                                                <img src={kroger} alt="Wings" />
                                                <img src={wallmart} alt="Wallmart" />
                                            </div>
                                            <div className="right_info">
                                                {
                                                    this.props.isLinked ?
                                                        <div>
                                                            {
                                                                showAppleWallet &&
                                                                <img className="apple-wallet" onClick={this.state.shwoApplePopup ? () => this.appleWalletPopup() : () => this.openAppleLink()} src={AppleWallet} alt="apple icon" />
                                                            }
                                                            {
                                                                showGoggleWallet &&
                                                                <a href="javascript:;" onClick={() => this.appleGooglePassClick('google')} style={{ pointerEvents: this.state.linkDisabled ? 'none' : 'fill' }}>
                                                                    <img className="google-wallet" src={GoogleWallet} alt="google icon" onClick={() => this.openGooglePass} />
                                                                </a>
                                                            }
                                                            <p>
                                                                <strong>www.nulifespanrx.com</strong><br />&copy; NuLifeSpan Rx <strong>2018-2024</strong>
                                                            </p>
                                                        </div> :
                                                        <div>
                                                            {
                                                                showAppleWallet &&
                                                                <img className="apple-wallet" onClick={this.state.shwoApplePopup ? () => this.appleWalletPopup() : () => this.openAppleLink()} src={AppleWallet} alt="apple icon" />
                                                            }
                                                            {
                                                                showGoggleWallet &&
                                                                <a href="javascript:;" onClick={() => this.appleGooglePassClick('google')} style={{ pointerEvents: this.state.linkDisabled ? 'none' : 'fill' }}>
                                                                    <img className="google-wallet" src={GoogleWallet} alt="google icon" onClick={() => this.openGooglePass} />
                                                                </a>
                                                            }
                                                            <p>
                                                                <a href="https://nulifespanrx.com/" target="_blank" className="no-styling"><strong>www.nulifespanrx.com</strong></a>
                                                                &copy; NuLifeSpan Rx <strong>2018-2024</strong>
                                                            </p>
                                                        </div>
                                                }
                                            </div>
                                            <div className="instructions">
                                                <h4>Pharmacist Instructions</h4> <p>This card is preactivated and gives member discounts through the BIN. PCN, GROUP on the front If you need assistance, call 811-800-7820</p>
                                            </div>
                                        </div>
                                </div>
                            </>
                        }</>
                }
                {
                    this.state.applePopup ? <AppleModal applePass={this.state.popupApplePass} closeModal={this.appleWalletPopup.bind(this)} /> : null
                }
            </>
        )
    }
}
export default CardBack;
