import React, { Component } from "react";
import Signup_header from "../Register/signup_header";

class TermsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            

        };
    }

    componentDidMount() {
       
        
    }

    render() {
        return (
            <>
            <div className="container_signup">
                <Signup_header />
                
                </div>
                <div className="terms_box">
                    <div className="container_signup">
                        <h1>Terms and Conditions</h1>
                        <p>As a condition to the willingness of NuLifeSpan Rx LLC (the &quot;Company&quot;) to contract with you as a distribution affiliate, you (&quot;Contractor&quot;), on behalf of yourself and each Contractor Party (as defined below), hereby acknowledge, covenant, and agree with the Company as follows:</p>
                        <ul>
                            <li>Contractor and each other Contractor Party (as defined below) shall abide by all laws, rules, and regulations relating to these terms and conditions and/or the distribution or advertising of the prescription discount program (the &quot;Program&quot;) and of the Company and its products, including, without limitation, all laws, rules, and regulations regulating discount prescription drug discount cards.</li>
                            <li>Contractor is responsible for the actions of Contractor and any and all of its employees, contractors, subcontractors, affiliates, agents and others acting directly or indirectly on its behalf (each a &quot;Contractor Party&quot; and collectively, the &quot;Contractor Parties&quot;) in the marketing or distribution of the Program as if such Contractor Parties were the Contractor itself and without regard to whether the Contractor Parties are agents of Contractor under applicable law.  Contractor hereby covenants and agrees that Contractor and all other Contractor Parties with whom Contractor contracts shall be subject to such legal and ethical compliance requirements that are no less stringent than those required by The Company and shall attest in writing to the same on no less than an annual basis and at any time requested by the Company.  At the Company&apos;s request, Contractor will terminate immediately any Contractor Party that the Company has reason to believe has violated these terms and conditions or any compliance requirements promulgated by Contractor, The Company, any pharmacy benefits manager with which the Company contracts or any pharmacy where the Program is eligible.  On request of the Company, Contractor shall provide to the Company a list, with contact information, of all Contractor Parties who at any time have been directly or indirectly engaged by or through Contractor to promote the Program, along with lists of all commissions or marketing fees paid to, or other financial transactions with, its Contractor Parties that relate to the Program.</li>
                            <li>Contractor must obtain the Company&apos;s prior written approval before any Contractor Party publishes or uses any marketing, solicitation, or advertising material (&quot;Marketing Material&quot;), including, but not limited to, INTERNET WEB SITES, SOCIAL MEDIA PLATFORMS, PRESCRIPTION CARDS, FLYERS, ARTICLES, MAILERS, or MEDIA BROADCASTS, to promote or implement the Program.  Failure to do so will be deemed a material breach of these terms and conditions.  Following approval by the Company, no Contractor Party shall alter, amend, or modify such approved Marketing Material in any material way without obtaining the prior written approval of The Company.  All Marketing Material shall include such disclaimers and disclosures required by applicable laws and regulation.  Contractor covenants and agrees that Contractor Parties will only use the Marketing Material and any other materials provided by the Company in connection with the Program in accordance with the terms and conditions set forth in these terms and conditions.  Contractor shall not make any representation regarding the Program that is deceptive and shall not engage in any unfair methods of promotion with respect to the Program.</li>
                            <li>Absent the Company&apos;s prior written authorization, neither Contractor nor any other Contractor Party will make any representation, statement, claim, or warranty about the Program or The Company or its products.  In furtherance of the foregoing, Contractor covenants and agrees, on behalf of itself and each Contractor Party, that Contractor will not make any representation, statement, claim, or warranty, expressly or impliedly, that the Program is insurance or is intended to replace any existing insurance coverage.  As noted above, Contractor and each other Contractor Party are expressly prohibited from charging any form of fee or imposing any additional cost (whether cash or otherwise) on any user to access the Program.</li>
                            <li>Contractor acknowledges and agrees that none of Contractor nor any other Contractor Party (whether directly or indirectly) will solicit, entice, contract, hire or otherwise engage any doctor, nurse, pharmacist or pharmacy technician or intern, any family member of any doctor, nurse, pharmacist or pharmacy technician or intern, or any person or entity acting by or through a doctor, nurse, pharmacist or pharmacy technician or intern, or any other employee of a hospital, medical practice or pharmacy (each, a &quot;Prohibited Person&quot;) to become a marketer, sales representative or other distribution agent of the Company Program for which such Prohibited Person is entitled to receive (either directly or indirectly) remuneration of any kind, including, without limitation, any cash compensation or other payment or benefit of monetary value (collectively, &quot;Prohibited Payments&quot;), nor will Contractor or any other Contractor Party request the assignment to any Prohibited Person of any the Company Program group number.  Contractor acknowledges and agrees that it is Contractor&apos;s responsibility to ensure that no Prohibited Person has or will receive a Company Program group number or any Prohibited Payments and shall implement such internal controls as is necessary to ensure the same.  For the avoidance of doubt, Contractor agrees and acknowledges that federal and state laws applicable to medical and prescription drug programs, such as anti-kickback statutes, may be deemed to apply to the Program, and that the Company shall not be responsible for any Contractor Party&apos;s compliance with those laws.  Contractor certifies that it is not a healthcare provider and that neither it nor any of its employees are Prohibited Persons.</li>
                            <li>As a condition to the Company&apos;s willingness to make the Program available to Contractor, Contractor will, on behalf of itself and any other Contractor Parties, provide to the Company, for its benefit and the benefit of any pharmacy at which the Program is eligible or any pharmacy benefits manager with whom such pharmacies have contracted, such compliance certifications and attestations as the Company may request with respect to the Contractor Parties&apos; compliance with these terms and conditions as well as any legal and ethical compliance program required by the Company or such pharmacies or any pharmacy benefit managers.  Failure to provide any required certification upon request will be deemed a material breach of these terms and conditions and grounds for immediate termination.</li>
                            <li>Contractor will use its reasonable best efforts to ensure that none of itself nor any other Contractor Party undertake any illegal or unethical behavior with the intention of influencing pharmacies in contravention of federal, state or local laws, rules or regulations. Contractor will use its reasonable best efforts to ensure that the Contractor Parties do not engage in any illegal or unethical behavior that, to the knowledge of Contractor, creates a conflict of interest for any pharmacy benefit manager with its network pharmacies. Contractor also shall not allow any Contractor Party to engage in any activity on Contractor&apos;s or any Contractor Party&apos;s behalf in which they interfere with the operation of a pharmacy by creating a conflict of interest related to compensation offered by Contractor or any other Contractor Party to pharmacy staff that has not been approved by the pharmacy, or any other incentivizing of pharmacy employees in a way that creates such a conflict. Contractor will conduct annual due diligence with any subcontractors on 1) adherence to contract terms; 2) confirming conformance to the Company&apos;s community-oriented mission; 3) confirming compliance with applicable federal, state and local laws, rules and regulations. Contractor will develop a FAQ for its contractors and other commercial partners with guidance and direction on appropriate and inappropriate marketing and distribution activities and will provide on its website an anonymous compliance hotline for its contractors and other commercial partners, as well as the end users of the Program, for reporting concerns associated with unethical behavior, fraud, or misuse of the Program. With reasonable advance notice, Contractor will allow the Company to audit (during normal business hours) as may be reasonably necessary to confirm compliance with this provision.</li>
                            <li>Contractor hereby represents, warrants, covenants and agrees that as of the date hereof and during the term hereof, Contractor is not a doctor, nurse, pharmacist or pharmacy technician or intern, a family member of any doctor, nurse, pharmacist or pharmacy technician or intern, or (whether individually or by virtue of the beneficial ownership of Contractor) acting by, through or on behalf of a doctor, nurse, pharmacist or pharmacy technician or intern, or any other employee of a hospital, medical practice or pharmacy.</li>
                            <li>Contractor represents and warrants that it has never been excluded from participation in any Federal Health Care Program pursuant to section 1128 of the Social Security Act (the &quot;Act&quot;) or from Medicare or a State health care program under section 1156 of the Act.  Contractor will not employ or engage any individual or entity who has been so excluded to provide any services with respect to the Program.  Exclusion of Contractor from any State or Federal health care program will give the Company the right to terminate any agreement between Contract and the Company on written notice to Contractor.</li>
                            <li>Contractor will indemnify and hold harmless the Company and each of its officers, directors, members, and employees, agents, affiliates, and successors and assigns from and against any and all losses, damages, claims, suits, proceedings, liabilities, costs, and expenses (including, without limitation, settlement costs, interest, penalties, and reasonable attorneys&apos; fees and any reasonable legal or other expenses for investigation or defense of any actions or threatened actions) (collectively &quot;Damages&quot;) which may be imposed on, sustained, incurred or suffered by or served against the Company, its officers, directors, members, employees, agents, affiliates or successors and assigns as a result of, relating to, arising out of, or in connection with (a) any negligence or willful misconduct of the Contractor or any Contractor Party, or any of their respective successors and assigns; or (b) any breach by the Contractor or any Contractor Party (as if such Contractor Party were the Contractor pursuant hereto) of any term or provision of these terms and conditions or any duty to the Company; or (c) the violation by Contractor or other Contractor Party or any of their respective successors and assigns of any federal, state or local rule, regulation, or laws.</li>
                            <li>Any third party with which the Company has contracted to provide the Program shall be an deemed an express third party beneficiary of these terms and conditions.</li>
                        </ul>
                    </div>
                </div>
                <div className="signup_footer"><div><a href="/terms">Terms</a> | <a href="https://rxhacker.com/privacy" target="_blank">Privacy</a></div><div>Copyright &copy; {new Date().getFullYear()} NuLifeSpan Rx. All rights reserved.</div></div>
                </>
        );
    }
}
export default TermsPage;
