import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import Panel from "./panel";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
//import UpdatedTerms from "../Register/UpdatedTerms";Commented because not in use

class FaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            activeTab: 0,
            shortname: '',
            fullName: '',
            account_info: '',
            FaqData:[]

        };
        this.activateTab = this.activateTab.bind(this);
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ UserData: Usermeta_Data.split(':')[0] });
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let userinfo = window.atob(localStorage.getItem('_file_info'));
        this.setState({ account_info: userinfo });
        let showemail = userService.getemail();
        //this.FaqList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        let signup_faqdata = [
            {
                Question: "Is this really FREE?",
                Answer: "Yes absolutely no cost to you now or ever. Not only do other companies pay you less but they charge you an initiation and monthly fee.We think that is outrageous."
            },
            {
                Question: "Why is it that agents in the NuLifeSpan Rx Distributor program make more money than anyone else in the industry?",
                Answer: "Our goal was to get our discount card spread nationwide. To accomplish that, we have to pay our reps the most in the industry and our card has to provide the greatest discount. We study and research every possible way to get our reps making the most money possible. There are no secrets – you get all the information that our top reps making $10k+ per month get."
            },
            {
                Question: "How much money can I make?",
                Answer: "You will earn a competitive commission anytime someone uses your card to pick up a prescription."
            },
            {
                Question: "How and when do I get paid?",
                Answer: "You get paid every month through PayQuicker. You can elect to get a check mailed to you, get direct deposit into your bank account, or have a personal debit card mailed to you that contains your funds. Payments are made monthly for the previous month’s transactions."
            },


            /*{
                Question: "Can I offer a doctor, pharmacist or other health care worker or pharmacy employee money or gifts to promote the Rx Hacker Program?",
                Answer: "No!  Absolutely not."
            },
            {
                Question: "Can I get a doctor, pharmacist or other health care worker or pharmacy employee to become an affiliate of the Rx Hacker Program?",
                Answer: "No!  No doctor, nurse, pharmacist, pharmacy intern or tech, or any other health care employee can serve as a marketer of the Rx Hacker Program."
            },
            {
                Question: "What if a doctor, pharmacist, healthcare worker or pharmacy employee asks me for payment in exchange for promoting the Rx Hacker Program?",
                Answer: "Politely decline and make it clear that such arrangements are against company policy. </br></br>You should report such requests to " + showemail + ".  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:<ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },*/
            {
                Question: "Do I have to get NuLifeSpan Rx’s approval of my web site or social media posts?",
                Answer: "Yes!  In order for us to ensure our marketing network complies with all applicable federal, state and local regulations, we require every marketer – and every contractor of every marketer – to submit their marketing materials – which includes, for example, your web site, social media posts, and marketing emails.  Failure to do so will subject your contract to immediate termination."
            },
            {
                Question: "What if I forget to get pre-approval of my social media or other online posts?",
                Answer: "You must immediately delete any such posts (whether inadvertent or not) and submit such inappropriately posted marketing material to <b>NuLifeSpan Rx</b> for review and approval. "
            },
            {
                Question: "What if one of my subcontractors uses marketing material that they did not submit for pre-approval?",
                Answer: "If you learn that one of your contractors has failed to secure pre-approval of their marketing material, you must immediately (1) cause that contractor to cease immediately the use of any such material and (2) report the same to NuLifeSpan Rx – together with the unapproved marketing materials – for review.  Any marketer – and any subcontractor of any marketer – is subject to immediate termination for failure to have your marketing material pre-approved by NuLifeSpan Rx."
            },
            /*{
                Question: "What if a pharmacist or other pharmacy employee asks me or a member of my team for a referral fee or some other payment or gift in connection with the Rx Hacker Program?",
                Answer: "You must report any such request – or even the suggestion of such a request – immediately to " + showemail +".  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options: <ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },*/
            {
                Question: "What if I have concerns that someone is out there distributing the NuLifeSpan Rx Program in violation of NuLifeSpan Rx’s compliance standards?",
                Answer: "If you have any such concerns, please contact <a href='mailto:" + showemail + "'>" + showemail +"</a> immediately so that we can address the situation as soon as possible.  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:<ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },
            {
                Question: "What if I have questions about compliance or about what marketing activities I can engage in?",
                Answer: "If you have any such questions, please contact your customer success manager, who can direct your questions to the appropriate NuLifeSpan Rx professional.  Of course, you can always reach out to <a href='mailto:" + showemail + "'>" + showemail +"</a> as well."
            }
        ]
        this.setState({ FaqData: signup_faqdata })
        
    }
    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }
    //FaqList(UserId, UserName) {
    //    userService.FaqList(UserId, UserName)
    //        .then(
    //            apidata => {
    //                if (apidata.length > 0) {
    //                    this.setState({ FaqData: JSON.parse(apidata[0].faq) })
    //                }
    //            }
    //        );
    //}

    render() {
        
       // const { panels } = this.props;Commented because not in use
        const { activeTab } = this.state;
        const panelsdata = this.state.FaqData;
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        /*if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) != true && this.state.account_info != 0) return <Redirect to="/account" />*/
        localStorage.setItem('pagename', 'faq');
       
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="6" account_info={this.state.account_info} fullName={this.state.fullName}/>
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="Card_Page_header no_border">
                                        <div className="h2_title">
                                            <h2>Frequently Asked Questions</h2>
                                            <p>Hello, welcome and thank you for joining us here at NuLifeSpan Rx! Here we will be addressing some frequently asked questions from our affiliates. Please take the time to read this so you are informed on some of the basics.</p>
                                        </div>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            {panelsdata.map((panel, index) =>
                                                <Panel
                                                    key={index}
                                                    activeTab={activeTab}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="6" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default FaqPage;