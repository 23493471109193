import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import Link_account from '../../images/link-account.svg';
import { userService } from "../../_services";
import ConfirmPopup from "../Consumer/ConfirmPopup";
import $ from 'jquery';

class WisePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: '',
            Address: '',
            Country: 'United States',
            State: '',
            City: '',
            Zip: '',
            fullName: '',
            carddetails: '',
            title: 'Payment Information',
            loader: false,
            Isshowpayment: false,
            show_Userdetail_popup: true,
            SuccessMessage: false,
            confirm: false,
            orderid: '',
            gn: '',
            date: '',
            show_confirm_popup: false,
            RoutingNumber: '',
            AccountNumber: '',
            AccountType:'',
            errors: {
                Address: '',
                State: '',
                City: '',
                Zip: '',
                orderid: '',
                gn: '',
                date: '',
                RoutingNumber: '',
                AccountNumber: '',
                AccountType: '',
                saveSubmit: ''
            },
            AddressData: {
                Country: [
                    'United States'
                ],
                AccountType: [
                    { name: 'Account Type', value: '' },
                    { name: 'CHECKING', value: 'CHECKING' },
                    { name: 'SAVINGS', value: 'SAVINGS' }
                ],
                State: [
                    { name: 'State', value: '' },
                    { name: 'Alabama', value: 'AL' },
                    { name: 'Alaska', value: 'AK' },
                    { name: 'Arizona', value: 'AZ' },
                    { name: 'Arkansas', value: 'AR' },
                    { name: 'California', value: 'CA' },
                    { name: 'Colorado', value: 'CO' },
                    { name: 'Connecticut', value: 'CT' },
                    { name: 'Delaware', value: 'DE' },
                    { name: 'Florida', value: 'FL' },
                    { name: 'Georgia', value: 'GA' },
                    { name: 'Hawaii', value: 'HI' },
                    { name: 'Idaho', value: 'ID' },
                    { name: 'Illinois', value: 'IL' },
                    { name: 'Indiana', value: 'IN' },
                    { name: 'Iowa', value: 'IA' },
                    { name: 'Kansas', value: 'KS' },
                    { name: 'Kentucky', value: 'KY' },
                    { name: 'Louisiana', value: 'LA' },
                    { name: 'Maine', value: 'ME' },
                    { name: 'Maryland', value: 'MD' },
                    { name: 'Massachusetts', value: 'MA' },
                    { name: 'Michigan', value: 'MI' },
                    { name: 'Minnesota', value: 'MN' },
                    { name: 'Mississippi', value: 'MS' },
                    { name: 'Missouri', value: 'MO' },
                    { name: 'Montana', value: 'MT' },
                    { name: 'Nebraska', value: 'NE' },
                    { name: 'Nevada', value: 'NV' },
                    { name: 'New Hampshire', value: 'NH' },
                    { name: 'New Jersey', value: 'NJ' },
                    { name: 'New Mexico', value: 'NM' },
                    { name: 'New York', value: 'NY' },
                    { name: 'North Carolina', value: 'NC' },
                    { name: 'North Dakota', value: 'ND' },
                    { name: 'Ohio', value: 'OH' },
                    { name: 'Oklahoma', value: 'OK' },
                    { name: 'Oregon', value: 'OR' },
                    { name: 'Pennsylvania', value: 'PA' },
                    { name: 'Rhode Island', value: 'RI' },
                    { name: 'South Carolina', value: 'SC' },
                    { name: 'South Dakota', value: 'SD' },
                    { name: 'Tennessee', value: 'TN' },
                    { name: 'Texas', value: 'TX' },
                    { name: 'Utah', value: 'UT' },
                    { name: 'Vermont', value: 'VT' },
                    { name: 'Virginia', value: 'VA' },
                    { name: 'Washington', value: 'WA' },
                    { name: 'West Virginia', value: 'WV' },
                    { name: 'Wisconsin', value: 'WI' },
                    { name: 'Wyoming', value: 'WY' }
                ]
            },
            stuck: false

        };
        this.noerrors = {
            Address: '',
            State: '',
            City: '',
            Zip: '',
            orderid: '',
            gn: '',
            date: '',
            RoutingNumber: '',
            AccountNumber: '',
            AccountType: '',
            saveSubmit: ''
        }
        this.submit_user_detail = this.submit_user_detail.bind(this);
        this.toggle_Payment_pop = this.toggle_Payment_pop.bind(this)
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserAccountInfo(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        this.setState({ show_Payment_popup: true });
    }
    componentWillMount() {}
    toggle_SucccessMessage = (e) => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let apptype = Usermeta_Data.split(':')[0];
    }
    toggle_Payment_pop = (e) => {
        e.stopPropagation();
        this.setState({ Isshowpayment: !this.state.Isshowpayment, show_Payment_popup: true, show_Userdetail_popup: true });
    }
    GetUserAccountInfo(UserId, UserName) {
        try {
            userService.GetUserAccountInfo(UserId, UserName)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].code !== "-1") {

                                    this.setState({ UserId: UserId, Address: apidata[0].address1, State: apidata[0].stateName, City: apidata[0].cityName, Zip: apidata[0].zipCode });

                                }
                                else {

                                }
                            }
                            else {

                            }
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    submit_user_detail = (e) => {
        e.stopPropagation();
        setTimeout(() => {
        try {
            let errors = this.state.errors;
            if (this.state.errors.saveSubmit.length > 0) {
                errors.saveSubmit = '';
            }
            if (this.state.Address.trim().length < 1 || this.state.Address.length < 1) {
                errors.Address = 'Please input street address';
            }
            if (this.state.Address.length > 25) {
                errors.Address = 'Please input valid street address';
                this.setState({ Address: '' });
            }
            if (this.state.State === 'Please select state' || this.state.State === '')
                errors.State = 'Please select State';
            if (this.state.City.length < 2) {
                errors.City = 'Please input valid city';
                this.setState({ City: '' });
            }
            if (this.state.City.length > 35) {
                errors.City = 'Please input valid city';
                this.setState({ City: '' });
            }
            if (this.state.Zip.length < 5 || this.state.Zip.length > 5) {
                errors.Zip = 'Please input valid zipcode';
                this.setState({ Zip: '' });
            }
            if (this.state.RoutingNumber.length < 9 || this.state.RoutingNumber.length > 9) {
                errors.RoutingNumber = 'Please input valid Routing Number';
                this.setState({ RoutingNumber: '' });
            }
            if (this.state.AccountNumber.length < 4 || this.state.AccountNumber.length > 17) {
                errors.AccountNumber = 'Please input valid Account Number';
                this.setState({ AccountNumber: '' });
            }
            if (this.state.AccountType === 'Account Type' || this.state.AccountType === '') {
                errors.AccountType = 'Please select Account Type';
                this.setState({ AccountNumber: '' });
            }
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                this.setState({ errors: errors });
            }
            else {
                let states_data = this.state;
                if (this.props.type === 'edit') {
                    this.toggle_confirm_popup_click(states_data.UserId, states_data.Address, states_data.Country, states_data.State, states_data.City, states_data.Zip, states_data.RoutingNumber, states_data.AccountNumber, states_data.AccountType);
                } else {
                    this.SaveAddress(states_data.UserId, states_data.Address, states_data.Country, states_data.State, states_data.City, states_data.Zip, states_data.RoutingNumber, states_data.AccountNumber, states_data.AccountType);
                }
            }
        }
        catch (error) {
            }
            
        }, 1800)
       
    }
    SaveAddress(UserId, Address, CountryName, StateName, CityName, ZipCode, RoutingNum, AccountNum, AccountType) {
        try {
            this.setState({
                loader: true
            })
            let errors = this.state.errors;
            userService.SaveAddress(UserId, Address, CountryName, StateName, CityName, ZipCode, RoutingNum, AccountNum, AccountType)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                this.setState({
                                    loader: false
                                })
                                document.getElementById('get_paid_button_cons').style.visibility = 'hidden';
                                this.props.toggle_user_detail();
                            }
                            else {
                               
                                errors.saveSubmit = apidata[0].message;
                                this.setState({
                                    loader: false
                                })
                                
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    SaveEditAddress(UserId, Address, CountryName, StateName, CityName, ZipCode, RoutingNum, AccountNum, AccountType) {
        try {
            this.setState({
                loader: true
            })
            let errors = this.state.errors;         
            userService.SaveEditAddress(UserId, Address, CountryName, StateName, CityName, ZipCode, RoutingNum, AccountNum, AccountType)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                this.setState({
                                    loader: false
                                })
                                this.props.toggle_user_detail();
                            }
                            else {
                                errors.saveSubmit = apidata[0].message;
                                this.setState({
                                    loader: false
                                })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    HandleInput = (event) => {
        let errors = this.state.errors;

        if (event.target.name === "City") {
            if (event.target.value !== "") {
                let special = /^.{1,255}$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }

        if (event.target.name === "Zip") {
            let str = event.target.value;
            str = str.replace(/[^0-9]/g, '');
            this.setState({ Zip: str });
            if (event.target.value !== "") {
                let special = /^.{1,32}$/;
                if (event.target.value.match(special) || event.target.value === "") {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        if (event.target.name === "RoutingNumber") {
            let str = event.target.value;
            str = str.replace(/[^0-9]/g, '');
            this.setState({ RoutingNumber: str });
            if (event.target.value !== "") {
                let special = /^\\d{9}$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        if (event.target.name === "AccountNumber") {
            let str = event.target.value;
            str = str.replace(/[^0-9]/g, '');
            this.setState({ AccountNumber: str });
            if (event.target.value !== "") {
                let special = /^\\d{4,17}$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        if (event.target.name === "Address") {
                let special = /^.{1,255}$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name] = '';
            this.setState({ errors: errors });
        }
    }
    handleChange = (event => {
        this.setState({ [event.target.name]: event.target.value });
    })
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    handleRbChange = (e) => {
        this.setState({ AccountType: e.target.value });
    }
    toggle_confirm_popup_click = () => {
        $('body').toggleClass('confirm');
        this.setState({ show_confirm_popup: true });
    }
    toggle_confirm_popup = () => {
        $('body').toggleClass('confirm');
        this.setState({ show_confirm_popup: !this.state.show_confirm_popup });
    }    
    confirmYes = (UserId, Address, Country, State, City, Zip, RoutingNumber, AccountNumber, AccountType) => {
        $('body').removeClass('confirm');
        this.setState({ confirm: true, show_confirm_popup: !this.state.show_confirm_popup });
        this.SaveEditAddress(UserId, Address, Country, State, City, Zip, RoutingNumber, AccountNumber, AccountType);
    }
    render() {
        const { errors } = this.state;
        
        return (
            <div>
                {this.state.show_Payment_popup === true ?
                    <div className="contentarea signup-popup order-section">
                        <div className="content_sec">
                            <div className="add_url_pop payment_popup">
                                <div className="Mask intro-kit">
                                    <div className="header">
                                        <div className="left">
                                            <h3>{this.props.type === 'edit' ? 'Edit your bank account' : 'Link your bank account' }</h3>
                                            <span>{this.props.type === 'edit' ? 'Add a new bank account to get payments credited directly! NuLifeSpan Rx uses Wise to securely create your payment account and does not store your bank details.' : 'Link your bank account to get payments credited directly! NuLifeSpan Rx uses Wise to securely create your payment account and does not store your bank details.'}</span>
                                        </div>
                                        <div className="right">
                                            <img src={Link_account} alt="" />
                                        </div>
                                        <div className="close" onClick={this.props.toggle_user_detail}>
                                            <img src={close_btn} alt="" />
                                        </div>
                                    </div>
                                    <div className="body">
                                        <div className="scroll-section">
                                            <div className="right-view">
                                                <div>
                                                    <div className="personal_box UserInfo">


                                                        <div className={errors.Address.length > 0 ? 'errors same full' : 'same full'}>
                                                            <input type="text" placeholder=" " name="Address" value={this.state.Address} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                            <span onClick={this.dofocus.bind(this)}>Address</span>
                                                            {errors.Address.length > 0 &&
                                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Address}</span>}
                                                        </div>
                                                        <div className="two-column">
                                                            <div className={errors.City.length > 0 ? 'errors same last-name' : 'same last-name'}>
                                                                <input type="text" placeholder=" " name="City" value={this.state.City} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                <span onClick={this.dofocus.bind(this)}>City*</span>
                                                                {errors.City.length > 0 &&
                                                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.City} </span>}
                                                            </div>
                                                            <div className={errors.State.length > 0 ? 'drpdown errors same first-name' : 'drpdown same first-name'}>
                                                                <select defaultValue={this.state.State} value={this.state.State}
                                                                    onChange={this.handleChange} name="State">
                                                                    {this.state.AddressData.State.map(item => <option key={item.name} value={item.value} >{item.name}</option>)}
                                                                </select>
                                                                <span>State*</span>
                                                                {errors.State.length > 0 &&
                                                                    <span onClick={this.removeError.bind(this)}>{errors.State} </span>}
                                                            </div>
                                                        </div>
                                                        <div className="two-column">
                                                            <div className={errors.Zip.length > 0 ? 'errors same first-name' : 'same first-name'}>
                                                                <input type="text" placeholder=" " name="Zip" value={this.state.Zip} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                <span onClick={this.dofocus.bind(this)}>Zip/Postal Code*</span>
                                                                {errors.Zip.length > 0 &&
                                                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Zip} </span>}
                                                            </div>
                                                        </div>
                                                        <div className={errors.AccountNumber.length > 0 ? 'errors same' : 'same'}>
                                                            <input type="text" placeholder=" " name="AccountNumber" value={this.state.AccountNumber} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                            <span onClick={this.dofocus.bind(this)}>Bank Account Number*</span>
                                                            {errors.AccountNumber.length > 0 &&
                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.AccountNumber} </span>}
                                                        </div>
                                                        <div className={errors.RoutingNumber.length > 0 ? 'errors same' : 'same'}>
                                                            <input type="text" placeholder=" " name="RoutingNumber" value={this.state.RoutingNumber} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                            <span onClick={this.dofocus.bind(this)}>Bank Routing Number*</span>
                                                            {errors.RoutingNumber.length > 0 &&
                                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.RoutingNumber} </span>}
                                                        </div>
                                                        <div className={errors.AccountType.length > 0 ? 'drpdown errors same' : 'drpdown same'}>
                                                            <select defaultValue={this.state.AccountType} value={this.state.AccountType}
                                                                onChange={this.handleChange} name="AccountType">
                                                                {this.state.AddressData.AccountType.map(item => <option key={item.name} value={item.value} >{item.name}</option>)}
                                                            </select>
                                                            <span>Account Type*</span>
                                                            {errors.AccountType.length > 0 &&
                                                                <span onClick={this.removeError.bind(this)}>{errors.AccountType} </span>}
                                                        </div>
                                                        <div className="footer">
                                                            <div className={this.state.loader ? "yes_btn loader" : "yes_btn"} onClick={this.submit_user_detail.bind(this)}>Save & Continue</div>
                                                        </div>
                                                        {errors.saveSubmit.length > 0 &&
                                                            <div className="submit-error">{errors.saveSubmit}</div>
                                                        }
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                { this.state.show_confirm_popup ?
                    <div className="Account_info_form_parent confirm">
                        <div className="resetgroup_box">
                            <ConfirmPopup UserId={this.state.UserId} Address={this.state.Address} Country={this.state.Country} State={this.state.State} City={this.state.City} Zip={this.state.Zip} RoutingNumber={this.state.RoutingNumber} AccountNumber={this.state.AccountNumber} AccountType={this.state.AccountType} confirmYes={this.confirmYes} closeConfirmPopup={this.toggle_confirm_popup} />
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}
export default WisePayment;