import React, { Component } from 'react';
import $ from 'jquery';
import { userService } from "../../../_services";
import moment from 'moment';
import Bar_List from '../BarList';
import Column_Pie from '../Column_Pie';
import MultiLine_List from '../MultiLineList';
import Claim from '../Claim';
import List from '../List';
import MultiStack_List from '../MultiStackBar_List';
import Left_Sidebar from '../../Admin/Admin_Common/Left_Sidebar';
import Header from '../../Admin/Admin_Common/Header';
import AnalyticsMenu from "../Analyticsmenu";
import { Redirect } from "react-router-dom";

// import FilterTagCom from '../FilterTag';


class Analytics extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();

        this.state = {
            startDate: new moment(startdate).format("YYYYMMDD"),
            endDate: new moment(enddate).format("YYYYMMDD"),
            graphData: [],
            scrollArray: {},
        }
        let newDate = new Date();
        localStorage.setItem('Filter_Last_Update_Time', newDate);
        this.handlechangea = this.handlechangea.bind(this);
        this.changeData();
    }
    componentDidMount() {
        this.changeData();
        $('.Dashboard_Area').addClass('dropdown-active');
        $('.sidebar-submenu').show();
        setTimeout(function () {
            var $head = $(".widget-iframe").contents().find("head");
            var $body = $(".widget-iframe").contents().find("body");
            $head.append($("<link/>",
                { rel: "stylesheet", href: "/table.css", type: "text/css" }));
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        }, 1000);
        $(window).resize(function () {
            var $body = $(".widget-iframe").contents().find("body");
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        });
        $('body').addClass('analyticPage');
    }
    componentWillUnmount() {
        $('body').removeClass('analyticPage');
    }
    handlechangea(e) {
        this.props.handeChangefilter(e);
    }
    changeData() {
        var APIBaseURL = '/api/ws';
        var wname = "admin"
        var startDateObj = this.props.startDate;
        var endDateObj = this.props.endDate;
        var startDate = "20230701";// new moment(startDateObj).format("YYYYMMDD");
        var endDate = "20230801"; // new moment(endDateObj).format("YYYYMMDD");
        this.setState({ startDate: startDate });
        this.setState({ endDate: endDate });
        let newDate = new Date();
        try {
            newDate = localStorage.getItem('Updated_Filter_Status');
        }
        catch (error) {
        }
        var AdFilterJson = '{"GroupNumber":["HDRX452"]}';
        //try {
        //    AdFilterJson = JSON.parse(localStorage.getItem('Advance_Filter'))
        //    if (AdFilterJson != null && AdFilterJson != '' && AdFilterJson != undefined && AdFilterJson != "null") {
        //        this.setState({ FilterTagList: JSON.stringify(AdFilterJson) });
        //    }
        //    else {
        //        this.setState({ FilterTagList: '' });
        //    }
        //}
        //catch (error) {
        //    this.setState({ FilterTagList: '' });
        //}

        var Request_user = "admin";
        var TotalTransactions_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "columnList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\"}";
        var Gr_No_Transactions_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "columnList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Unique_Members_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "columnList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Drug_Transactions_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "pielist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var New_Repeat_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "multilinelist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Claim_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Table_Pharmacy_Detail_Widget_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Commission_MultiStack_Column_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "column_multiaxislist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + "Wed Aug 02 2023 14:25:53 GMT+0530 (India Standard Time)" + "\",\"rxsClaimType\"" + ":\"" + "totalpaid" + "\"}";

        this.setState({
            dashboardName: wname,
            graphData: [
                {
                    url: APIBaseURL + "/total_transactions_new",
                    type: "Bar_List",
                    title: "Total Transactions",
                    Subtitle: "Total number of transactions for each day",
                    ColorCode: '#000000',
                    DataType: "NA",
                    XlabelString: "Date",
                    YlabelString: "# of Transactions",
                    interval: 'day',
                    id: "WTotalTransaction",
                    RequestJson: TotalTransactions_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                }              
                //{
                //    url: APIBaseURL + "/Transactions_By_Group_Number",
                //    type: "Bar_List",
                //    title: "Transactions by Group Number",
                //    Subtitle: "Number of transactions for each group number",
                //    ColorCode: "#000000",
                //    DataType: "GroupNumber",
                //    XlabelString: "Group Number",
                //    YlabelString: "# of Transactions",
                //    id: "WGroupNumberTransaction",
                //    RequestJson: Gr_No_Transactions_PostData_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //},
                //{
                //    url: APIBaseURL + "/Unique_Members_Widget",
                //    type: "Bar_List",
                //    title: "Unique Member ID's",
                //    Subtitle: "Number of unique members transacted on each day",
                //    ColorCode: "#000000",
                //    DataType: "NA",
                //    XlabelString: "Date",
                //    YlabelString: "# of Unique Member ID's",
                //    interval: 'day',
                //    id: "WUniqueMembersWidget",
                //    RequestJson: Unique_Members_Widget_PostData_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //},
                //{
                //    url: APIBaseURL + "/Drug_Wise_Transaction",
                //    type: "Column_Pie",
                //    title: "Transactions by Drug",
                //    Subtitle: "Number of transactions for each drug",
                //    ColorCode: "#000000",
                //    DataType: "DrugName",
                //    XlabelString: "Date",
                //    YlabelString: "# of Unique Member ID's",
                //    interval: 'day',
                //    id: "WDrugTransaction",
                //    RequestJson: Drug_Transactions_Widget_PostData_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //},
                //{
                //    url: APIBaseURL + "/new_and_repeat_claims_Line",
                //    type: "MultiLine_List",
                //    title: "New vs Repeat Transactions",
                //    Subtitle: "New vs Repeat Compensable Transactions",
                //    ColorCode: "#000000",
                //    DataType: "NA",
                //    XlabelString: "Date",
                //    YlabelString: "# of Transactions",
                //    interval: 'day',
                //    id: "WNewRepeatMultiLine",
                //    RequestJson: New_Repeat_Widget_PostData_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //}
                //{
                //    url: APIBaseURL + "/RXSClaims",
                //    type: "Claim",
                //    title: "Claim Counts",
                //    Subtitle: "",
                //    ColorCode: "#000000",
                //    id: "RxSensClaim",
                //    RequestJson: Claim_PostData_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //}
                //{
                //    url: APIBaseURL + "/Pharmacy_Detail_Wise_Transaction",
                //    type: "List",
                //    title: "Transactions by Pharmacy",
                //    Subtitle: "Number of transactions at each pharmacy",
                //    XlabelString: "",
                //    YlabelString: "",
                //    id: "WPharmacyDetailTransaction",
                //    RequestJson: Table_Pharmacy_Detail_Widget_RequestJson,
                //    RequestJson1: JSON.stringify(AdFilterJson)
                //},
            //    {
            //        url: APIBaseURL + "/EarnedCommission",
            //        type: "MultiStackBar_List",
            //        title: "Total Revenue Earned",
            //        Subtitle: "Revenue earned for each month",
            //        ColorCode: "#000000",
            //        DataType: "NA",
            //        XlabelString: "Date",
            //        YlabelString: "Commission",
            //        interval: 'month',
            //        id: "WEarnedCommission",
            //        RequestJson: Commission_MultiStack_Column_PostData_RequestJson,
            //        RequestJson1: JSON.stringify(AdFilterJson)
            //    }
            ]
        });
    }

    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'analyticsPage');

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <>
                <div>
                    <div className="Dashboard_Area">
                        <Left_Sidebar Activemenu="22" fullName={this.state.fullName} />
                        <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                            <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                            <div className="content_sec admin_sec">
                                <h2>Signups<span class="beta-link"><span class="beta">Beta</span></span></h2>
                                <AnalyticsMenu handeChangefilter={this.handeChangefilter} dashboardListRendered={this.dashboardListRendered} handleChangeDateNew={this.handleChangeStartEnd} />

                                <div className="dashboard-content-parent">
                                    <div className="newBoard">
                                        <div className="parent">
                                            <div className="contentContainer">
                                                <div>
                                                    <div className='row'>
                                                        <div className='double-column'>
                                                            <div className="dashboard-container" id="dashboard-container">
                                                                {
                                                                    this.state.graphData.map((item, index) => {
                                                                        return (
                                                                            <div id={item.id + index} className={`${item.type == "Bar" ? "widget ui-draggable ui-resizable" : item.type == "Line" ? "widget ui-draggable ui-resizable second" : "widget ui-draggable ui-resizable customScroll third"}${item.id == "WTotalTransaction" ? ' full-layout barlist' : ''}${item.id == "WGroupNumberTransaction" ? ' full-layout barlist' : ''}${item.id == "WUniqueMembersWidget" ? ' full-layout barlist' : ''}${item.id == "WDrugTransaction" ? ' full-layout barlist' : ''}${item.id == "WNewRepeatMultiLine" ? ' full-layout barlist' : ''}${item.id == "RxSensClaim" ? ' full-layout barlist' : ''}${item.id == "WEarnedCommission" ? ' full-layout barlist' : ''}${item.id == "WPharmacyDetailTransaction" ? ' full-layout list' : ''}`} key={"graph-widget" + index}>
                                                                                <div className="widget-head" key={"graph-widget-head" + index}>
                                                                                    <h3>
                                                                                        <a data-original-title>{item.title}</a>
                                                                                    </h3>
                                                                                    <p>
                                                                                        <a data-original-title>{item.Subtitle}</a>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="widget-content" key={"graph-widget-content" + index}>
                                                                                    <div className="custom-scroll">
                                                                                        <div className="scroll-content" style={{ height: this.state.scrollArray[this.state.graphData[index].id] }}>
                                                                                            {item.type == "Bar_List" ? <Bar_List data={item} key={"barlistgraph" + index} _Index={index} handlechangea={this.handlechangea} /> : null}
                                                                                            {item.type == "Column_Pie" ? <Column_Pie data={item} key={"columnpiegraph" + index} _Index={index} handlechangea={this.handlechangea} /> : null}
                                                                                            {item.type == "MultiLine_List" ? <MultiLine_List data={item} key={"multilinelistgraph1" + index} _Index={index} /> : null}
                                                                                            {item.type == "Claim" ? <Claim data={item} key={"claimbox" + index} _Index={index} /> : null}
                                                                                            {item.type == "List" ? <List data={item} key={"claimbox" + index} _Index={index} /> : null}
                                                                                            {item.type == "MultiStackBar_List" ? <MultiStack_List data={item} key={"barlistgraph1" + index} _Index={index} /> : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="widget-foot">
                                                                                    &nbsp;
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </> : <Redirect to="/page-not-found" />
        )
    }
}
export default Analytics;