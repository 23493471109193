import React, { Component } from 'react';
import $ from 'jquery';
import { userService } from "../../../_services";
import moment from 'moment';
import Bar_List from '../BarList';
import Column_Pie from '../Column_Pie';
import MultiLine_List from '../MultiLineList';
import Claim from '../Claim';
import List from '../List';
import Line_List from '../LineList';
import MultiStack_List from '../MultiStackBar_Dist_List';
import FilterTagCom from '../FilterTag';
import { Redirect } from "react-router-dom";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();
        if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
            startdate = localStorage.getItem('User_Start_Date');
        }
        if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
            enddate = localStorage.getItem('User_End_Date');
        }
        let filterdatalocal = localStorage.getItem('Advance_Filter');
        if (filterdatalocal == null || filterdatalocal == '' || filterdatalocal == undefined || filterdatalocal == "null") {
            filterdatalocal = '';
        }
        let logged_in_userid = '0';
         let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
         logged_in_userid = Usermeta_Data.split(':')[0];
        //setTimeout(() => {
        //    logged_in_userid = Usermeta_Data.split(':')[0];
        //}, 100)
        this.state = {
            endDate: new moment(enddate).format("YYYYMMDD"),
            startDate: new moment(startdate).format("YYYYMMDD"),
            graphData: [],
            scrollArray: {},
            dist_usertype: this.props.dist_usertype,
            FilterTagList: filterdatalocal,
            rolename: logged_in_userid,
            wh_Id: '61',
            roletype: window.atob(localStorage.getItem('_Host_Pro_type')),
            counter: 0,
            Filter_Last_Update_Time: '',
            Logged_In_Userid: logged_in_userid
        }
        let newDate = new Date();
        localStorage.setItem('Filter_Last_Update_Time', newDate);
        this.handlechangea = this.handlechangea.bind(this);
        this.changeData();
    }
    componentDidMount() {
        this.changeData();
        setTimeout(function () {
            var $head = $(".widget-iframe").contents().find("head");
            var $body = $(".widget-iframe").contents().find("body");
            $head.append($("<link/>",
                { rel: "stylesheet", href: "/table.css", type: "text/css" }));
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        }, 1000);
        $(window).resize(function () {
            var $body = $(".widget-iframe").contents().find("body");
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        });

    }
    componentDidUpdate() {
        if ((this.state.startDate != new moment(this.props.startDate).format("YYYYMMDD") || this.state.endDate != new moment(this.props.endDate).format("YYYYMMDD")) || (localStorage.getItem('Prev_HD_Type') != localStorage.getItem('Updated_HD_Type')) || (localStorage.getItem('Filter_Last_Update_Time') != localStorage.getItem('Filter_Prev_Update_Time'))) {
            this.setState({
                endDate: new moment(this.props.endDate).format("YYYYMMDD"),
                startDate: new moment(this.props.startDate).format("YYYYMMDD")
            });
            localStorage.setItem('dist_usertype', this.props.dist_usertype);
            if ((localStorage.getItem('Prev_HD_Type') != localStorage.getItem('Updated_HD_Type'))) {
                var LocalHDType = localStorage.getItem('Updated_HD_Type');
                localStorage.setItem('Prev_HD_Type', LocalHDType);
            }
            if ((localStorage.getItem('Filter_Last_Update_Time') != localStorage.getItem('Filter_Prev_Update_Time'))) {
                var LocalupdateTime = localStorage.getItem('Filter_Last_Update_Time');
                localStorage.setItem('Filter_Prev_Update_Time', LocalupdateTime);
                this.setState({ Filter_Last_Update_Time: LocalupdateTime });
            }
            this.changeData();
        }
    }
    handlechangea(e) {
        this.props.handeChangefilter(e);
    }
    changeData(dname, stdate, endate) {
        var APIBaseURL = ''
        var startDateObj = '';
        var endDateObj = '';
        var wname = "distributor"
        if (this.props == undefined) {
            // wname = dname;
            startDateObj = stdate;
            endDateObj = endate;
        }
        else {
            //wname = this.props.dashboardName;
            startDateObj = this.props.startDate;
            endDateObj = this.props.endDate;
        }
        var AllDirectOverride = this.props.dist_usertype;
        if (AllDirectOverride === 'All')
            APIBaseURL = '/api/userAll';
        else if (AllDirectOverride === 'Direct')
            APIBaseURL = '/api/userDirect';
        else if (AllDirectOverride === 'Override')
            APIBaseURL = '/api/userOverride';
        else
            APIBaseURL = '/api/userDirect';
        var startDate = new moment(startDateObj).format("YYYYMMDD");
        var endDate = new moment(endDateObj).format("YYYYMMDD");
        var AdFilterJson = '', _string_AdFilterJson;
        try {
            AdFilterJson = JSON.parse(localStorage.getItem('Advance_Filter'))
            if (AdFilterJson != null && AdFilterJson != '' && AdFilterJson != undefined && AdFilterJson != "null") {
                _string_AdFilterJson = JSON.stringify(AdFilterJson);
            }
            else {
                _string_AdFilterJson = '';
            }
        }
        catch (error) {
            _string_AdFilterJson = '';
        }
        let _counter = 0
        _counter = this.state.counter + 1;
        this.setState({ startDate: startDate, endDate: endDate, FilterTagList: _string_AdFilterJson, counter: _counter });

        var TotalTransactions_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "columnList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Claim_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Gr_No_Transactions_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "columnList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var PatientSaving_Widget_Postdata_RequestJson_Value = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "lineList\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var PatientSaving_Widget_Postdata_RequestJson_Percent = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "lineList\"" + ",\"ispatientsaving\"" + ":\"" + "false\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var State_Transactions_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "pielist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Table_Pharmacy_Wise_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Drug_Transactions_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var Commission_MultiStack_Column_PostData_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 0 + ",\"chart_type\"" + ":\"" + "column_multiaxislist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Table_Sub_Distributors_Wise_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Table_Prescriber_Wise_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Table_PatientSavings_Pharmacy_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";
        var Table_Repeat_Prescriptions_Wise_RequestJson = "{\"wname\"" + ":\"" + this.state.Logged_In_Userid + "\",\"whname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "list\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\"}";

        this.setState({
            dashboardName: wname,
            graphData: [
                {
                    url: APIBaseURL + "/total_transactions",
                    type: "Bar_List",
                    title: "Total Transactions",
                    Subtitle: "Total number of transactions for each day",
                    ColorCode: '#000000',
                    DataType: "NA",
                    XlabelString: "Date",
                    YlabelString: "# of Transactions",
                    interval: 'day',
                    id: "WTotalTransaction",
                    RequestJson: TotalTransactions_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/RXSClaims",
                    type: "Claim",
                    title: "Claim Counts",
                    Subtitle: "",
                    ColorCode: "#000000",
                    id: "RxSensClaim",
                    RequestJson: Claim_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/group_number_transactions",
                    type: "Bar_List",
                    title: "Transactions by Group Number",
                    Subtitle: "Number of transactions for each group number",
                    ColorCode: "#000000",
                    DataType: "GroupNumber",
                    XlabelString: "Group Number",
                    YlabelString: "# of Transactions",
                    id: "WGroupNumberTransaction",
                    RequestJson: Gr_No_Transactions_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/avg_PaitentSavings_values_percent",
                    type: "Line_List",
                    title: "Average Patient Savings ($)",
                    Subtitle: "Average savings for all patients in $",
                    ColorCode: '#000000',
                    DataType: "NA",
                    XlabelString: "Date",
                    YlabelString: "Total Savings",
                    id: "WPatientSavings_Value",
                    RequestJson: PatientSaving_Widget_Postdata_RequestJson_Value,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/avg_PaitentSavings_values_percent",
                    type: "Line_List",
                    title: "Average Patient Savings (%)",
                    Subtitle: "Average savings for all patients in %",
                    ColorCode: '#000000',
                    DataType: "NA",
                    XlabelString: "Date",
                    YlabelString: "Total Savings",
                    id: "WPatientSavings_Percent",
                    RequestJson: PatientSaving_Widget_Postdata_RequestJson_Percent,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/state_transaction",
                    type: "Column_Pie",
                    title: "Transactions by State",
                    Subtitle: "Number of transactions in each state",
                    ColorCode: "#000000",
                    DataType: "DrugName",
                    XlabelString: "Date",
                    YlabelString: "# of Unique Member ID's",
                    interval: 'day',
                    id: "DStateTransaction",
                    RequestJson: State_Transactions_Widget_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/pharmacy_transaction",
                    type: "List",
                    title: "Transactions by Pharmacy",
                    Subtitle: "Number of transactions at each pharmacy",
                    ColorCode: "",
                    XlabelString: "",
                    YlabelString: "",
                    id: "DPharmacyTransaction",
                    BorderRadius: 0,
                    RequestJson: Table_Pharmacy_Wise_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/drug_transaction",
                    type: "List",
                    title: "Transactions by Drug",
                    Subtitle: "Number of transactions for each drug",
                    ColorCode: "",
                    XlabelString: "",
                    YlabelString: "",
                    id: "DDrugTransaction",
                    BorderRadius: 0,
                    RequestJson: Drug_Transactions_Widget_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/earned_commission",
                    type: "MultiStackBar_List",
                    title: "Total Revenue Earned",
                    Subtitle: "Revenue earned for each month",
                    ColorCode: "#EE934A",
                    DataType: "NA",
                    XlabelString: "Date",
                    YlabelString: "Commission",
                    interval: 'month',
                    id: "WEarnedCommission",
                    RequestJson: Commission_MultiStack_Column_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/dist_subdist_transactions",
                    type: "List",
                    title: "Transactions (Sub-Distributors)",
                    Subtitle: "Transactions including sub-distributors",
                    XlabelString: "",
                    YlabelString: "",
                    ColorCode: "",
                    id: "DTransactions",
                    BorderRadius: 0,
                    RequestJson: Table_Sub_Distributors_Wise_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/prescriber_transaction",
                    type: "List",
                    title: "Transactions by Prescriber",
                    Subtitle: "Number of transactions at each Prescriber",
                    ColorCode: "",
                    XlabelString: "",
                    YlabelString: "",
                    id: "WPrescriberTransaction",
                    RequestJson: Table_Prescriber_Wise_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/pharmacy_patient_savings",
                    type: "List",
                    title: "Patient Savings by Pharmacy",
                    Subtitle: "Patient savings in $ at each pharmacy",
                    ColorCode: "",
                    XlabelString: "",
                    YlabelString: "",
                    id: "DPatientSavingTransaction",
                    BorderRadius: 0,
                    RequestJson: Table_PatientSavings_Pharmacy_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/repeat_prescriptions_transactions",
                    type: "List",
                    title: "Transactions by Repeat Prescriptions",
                    Subtitle: "Number of repeat prescriptions by each Prescriber",
                    ColorCode: "",
                    XlabelString: "",
                    YlabelString: "",
                    id: "WRepeat_PrescriptionsTransaction",
                    RequestJson: Table_Repeat_Prescriptions_Wise_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                }
            ]
        });
    }

    render() {
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <>
                <div className="dashboard-content-parent">
                    <div className="newBoard">
                        <div className="parent">
                            <div className="contentContainer">
                                <div>
                                    <div className='row'>
                                        <div className='double-column'>
                                            <div className="dashboard-container" id="dashboard-container">
                                                <FilterTagCom Rolename={this.state.rolename} wh_Id={this.state.wh_Id} Roletype={this.state.roletype} df={this.state.startDate} dt={this.state.endDate} handlechangea={this.handlechangea} FilterTags={this.state.FilterTagList} Loading={true} />
                                                {
                                                    this.state.graphData.map((item, index) => {
                                                        return (
                                                            <div id={item.id + index} className={`${item.type == "Bar" ? "widget ui-draggable ui-resizable" : item.type == "Line" ? "widget ui-draggable ui-resizable second" : "widget ui-draggable ui-resizable customScroll third"}${item.id == "WTotalTransaction" ? ' full-layout barlist' : ''}${item.id == "RxSensClaim" ? ' full-layout barlist' : ''}${item.id == "WGroupNumberTransaction" ? ' layout-not-margin barlist' : ''}${item.id == "WPatientSavings_Value" ? ' layout-left-margin barlist' : ''}${item.id == "WPatientSavings_Percent" ? ' layout-not-margin barlist' : ''}${item.id == "DStateTransaction" ? ' layout-left-margin barlist' : ''}${item.id == "DPharmacyTransaction" ? ' full-layout barlist' : ''}${item.id == "DDrugTransaction" ? ' layout-not-margin barlist' : ''}${item.id == "WEarnedCommission" ? ' layout-left-margin barlist' : ''}${item.id == "DTransactions" ? ' full-layout barlist' : ''}${item.id == "WPrescriberTransaction" ? ' full-layout barlist' : ''}${item.id == "DPatientSavingTransaction" ? ' layout-not-margin barlist' : ''}${item.id == "WRepeat_PrescriptionsTransaction" ? ' layout-left-margin barlist' : ''}`} key={"graph-widget" + index}>
                                                                <div className="widget-head" key={"graph-widget-head" + index}>
                                                                    <h3>
                                                                        <a data-original-title>{item.title}</a>
                                                                    </h3>
                                                                    <p>
                                                                        <a data-original-title>{item.Subtitle}</a>
                                                                    </p>
                                                                </div>
                                                                <div className="widget-content" key={"graph-widget-content" + index}>
                                                                    <div className="custom-scroll">
                                                                        <div className="scroll-content" style={{ height: this.state.scrollArray[this.state.graphData[index].id] }}>
                                                                            {item.type == "Bar_List" ? <Bar_List data={item} key={"barlistgraph" + index} _Index={index} handlechangea={this.handlechangea} /> : null}
                                                                            {item.type == "Line_List" ? <Line_List data={item} key={"linelistgraph" + index} _Index={index} /> : null}
                                                                            {item.type == "Column_Pie" ? <Column_Pie data={item} key={"columnpiegraph" + index} _Index={index} handlechangea={this.handlechangea} /> : null}
                                                                            {item.type == "MultiLine_List" ? <MultiLine_List data={item} key={"multilinelistgraph1" + index} _Index={index} /> : null}
                                                                            {item.type == "Claim" ? <Claim data={item} key={"claimbox" + index} _Index={index} /> : null}
                                                                            {item.type == "List" ? <List data={item} key={"claimbox" + index} _Index={index} handlechangea={this.handlechangea} /> : null}
                                                                            {item.type == "MultiStackBar_List" ? <MultiStack_List data={item} key={"barlistgraph1" + index} _Index={index} /> : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-foot">
                                                                    &nbsp;
                                                                        </div>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <Redirect to="/page-not-found" />
        )
    }
}
export default Dashboard;