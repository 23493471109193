export const userService = {
    Check_Null_Empty,
    logout,
    ValidEmailRegex,
    login,
    GetUserDashboardDetails,
    Save_Update_ReferralURL,
    GetReferralURLList,
    Delete_ReferralURL,
    Signup,
    CheckAvailability,
    ActivateAccount,
    PricingToolInitialStatus,
    getToken,
    getTokenBased_UserInfo,
    GetGroupNumberList,
    GetReferralList,
    GetUserSummaryDetail,
    GetUserAccountInfo,
    SetUserAccountInfo,
    ResetPassword,
    FaqList,
    GroupList,
    GetRepsOverrides,
    GetOverridesOfUser,
    groupengae,
    resetgroup,
    resetprimarygroup,
    GetUserList,
    DetailOfUser,
    UpdateUserDetail,
    UpdateGroupNumber,
    updateuserpassword,
    UpdateUserStatus,
    GetParentChild,
    DeactivateParentChild,
    GetUserGroups,
    GetConsumerGroups,
    GetPricingToolRequest,
    ManagePricingTool,
    SubmitQuestion,
    pwdresetlink,
    validatepwdresetlink,
    updatepwd_bylink,
    verifytuser,
    updatetuser,
    submit_referral_link_request,
    Approve_referral_link_request,
    submit_docusign,
    Get_User_ReffralLinks,
    Update_User_ReffralLinks,
    Referral_Reject,
    ReferLinkUser,
    ValidPhone,
    AddNewUser,
    verifyuser_organic,
    update_orguser,
    getemail,
    CheckPricingtool,
    claimdashboard,
    CreatePayment,
    SetTandCUpdate,
    GetTandCUpdate,
    GetPaymentStuff,
    GoogleSheetEntry,
    GetApprovePayment,
    GetBinList,
    GetGroupNoTransaction,
    GetUserTransactionByBin,
    ResendAccountActivationEmail,
    Get_UserTierProperties,
    GetListOfMonth,
    GetUserTierReport,
    GetUserNameFilter,
    GetUserTransaction,
    GetPhoneNoValidate,
    GetTransaction6m,
    CreateNewUserByAdmin,
    SaveSecondLink,
    GetSecondLink,
    EditSecondLink,
    ValidateSecondLink,
    GetTypeaheadUsername,
    Signup_secLink,
    ActivateAccount_secLink,
    GetUserReferalBonus,
    UpdateTierCommissionSetting,
    TrainingMedia,
    CreateModule,
    EditModule,
    DeleteModule,
    AddMedia,
    EditMedia,
    DeleteMedia,
    GetMonthYear,
    SecondaryCommTracking,
    SecondaryCommTrackingSubDist,
    SecondaryCommTracking_Tier_NonTier,
    SecondaryCommTrackingSubDist_Tier_NonTier,
    Swap_Media,
    Swap_Module,
    GetUserOverride,
    GetTransaction,
    GetParentReferral,
    GetUsetLogin,
    GetUserReps,
    GetListOfMonthPayout,
    Summary_Details,
    Get_FAQ,
    Share_Card,
    Paid_transaction_box,
    apple_google_wallet_pass_link,
    ConsumerToDistributor,
    IphoneChromeEmail,
    GetAccountNumber,
    SaveAddress,
    SaveEditAddress,
    DeactivateAccount,
    passredirect,
    apple_google_wallet_pass_link_New,
    Validate_ReferralURL,
    pqInvite
};
function Check_Null_Empty(val) {
    if (val === '' || val === null || val == undefined || val === "null" || val === "{}")
        return true;
    else
        return false;
}
async function Check_Is_Null_Empty(val) {
    if (val === '' || val === null || val == undefined || val === "null" || val === "{}")
        return true;
    else
        return false;
}

function logout() {
    var temp_referral = localStorage.getItem('ReferralURL');
    if (window.atob(localStorage.getItem('_Host_Pro_type')) === "consumer") {
        let my_current_url = window.location.origin;
        localStorage.clear();
        localStorage.removeItem('ajs_user_id');
        localStorage.removeItem('ReferralURL');
        localStorage.removeItem('_Host_Pro_Hckare_TKN');
        localStorage.removeItem('_Secure_3PSID_DT');
        if (my_current_url.toLowerCase().includes("localhost:") || my_current_url.toLowerCase().includes("release-backoffice") || my_current_url.toLowerCase().includes("dev-backoffice")) {
            return window.location.href = "https://dev.nulifespanrx.com";
        } else {
            return window.location.href = "https://nulifespanrx.com";
        }
    }
    else {
        localStorage.clear();
        localStorage.removeItem('ajs_user_id');
        localStorage.removeItem('ReferralURL');
        localStorage.removeItem('_Host_Pro_Hckare_TKN');
        localStorage.removeItem('_Secure_3PSID_DT');
    }
    localStorage.setItem('ReferralURL', temp_referral);

}
function ValidEmailRegex(EmailId) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(EmailId).toLowerCase()) === false)
        return false;
    else
        return true;
}
function ValidPhone(phone) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (re.test(String(phone).toLowerCase()) === false)
        return false;
    else
        return true;
}

async function CallApi(Url, Bodyjson) {
    var logon_time = ''; var user_token = '';
    try {
        if (Check_Is_Null_Empty(localStorage.getItem('_Secure_3PSID_DT')) === false && Check_Is_Null_Empty(localStorage.getItem('_Host_Pro_Hckare_TKN')) === false) {
            logon_time = window.atob(localStorage.getItem('_Secure_3PSID_DT'));
            user_token = window.atob(localStorage.getItem('_Host_Pro_Hckare_TKN'));

        }
    }
    catch { }
    try {
        return await fetch(Url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader_TKN': user_token, 'requestHeader_DT': logon_time },
            body: Bodyjson
        }).then(handleResponse).then(apidata => {
            return apidata;
        });
    }
    catch (err) {
    }
}


async function CallApi_withheader(Url, Bodyjson, HeaderJson) {
    try {
        return await fetch(Url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'OverridesJson': HeaderJson },
            body: Bodyjson
        }).then(handleResponse).then(apidata => {
            return apidata;
        });
    }
    catch (error) {
    }

}
async function SwapMedia_withheader(Url, Bodyjson, HeaderJson) {
    try {
        return await fetch(Url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'SwappingJson': HeaderJson },
            body: Bodyjson
        }).then(handleResponse).then(apidata => {
            return apidata;
        });
    }
    catch (error) {
    }

}
async function SwapModule_withheader(Url, Bodyjson, HeaderJson) {
    try {
        return await fetch(Url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'SwappingJson': HeaderJson },
            body: Bodyjson
        }).then(handleResponse).then(apidata => {
            return apidata;
        });
    }
    catch (error) {
    }

}


async function login(UserName, Password) {
    return CallApi(`/api/Account/Login`, JSON.stringify({ UserName, Password }))
        .then(apidata => {
            try {
                if (apidata.length > 0) {
                    if (apidata[0].code !== "-1") {
                        localStorage.setItem('hacker_user_detail', window.btoa(apidata[0].shortName + ':' + apidata[0].fullName));
                        localStorage.setItem('ajs_user_id', window.btoa(apidata[0].code + ':' + apidata[0].message));
                        localStorage.setItem('_Secure_3PSID_DT', window.btoa(apidata[0].loginOn));
                        localStorage.setItem('_Host_Pro_Hckare_TKN', window.btoa(apidata[0].userToken));
                        localStorage.setItem('UserRole', window.btoa(apidata[0].userRole));

                        localStorage.setItem('_file_info', window.btoa(apidata[0].accInfo));
                        localStorage.setItem('_Host_Pro_type', window.btoa(apidata[0].userRole));
                        localStorage.setItem('_Host_Pro_type', window.btoa(apidata[0].userRole));
                        localStorage.setItem('wt19:awaydpset', window.btoa(apidata[0].objectUid));
                        localStorage.setItem('_Host_Overr_tnuoc_var', apidata[0].overrideCount);
                        //localStorage.setItem('_file_info', window.btoa(apidata[0].accInfo)); 
                        //localStorage.setItem('_file_info', window.btoa('0'));
                    }
                    else {
                        this.logout();
                    }
                }
                else {
                    this.logout();
                }
            }
            catch (error) {
                this.logout();
            }
            return apidata
        });
}

async function GetUserDashboardDetails(UserId, UserName) {
    try {
        return CallApi(`/api/Account/Dashboard_Profile`, JSON.stringify({ UserId, UserName }))
            .then(apidata => {
                return apidata
            });
    }
    catch (error) {
    }
}

async function CheckAvailability(Phone, Email, UserName, IsUserName) {
    return CallApi(`/api/Account/checkavailability`, JSON.stringify({ Phone, Email, UserName, IsUserName }))
        .then(apidata => {
            return apidata
        });
}

async function Signup(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid) {
    return CallApi(`/api/Account/CreateAccount`, JSON.stringify({ Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid }))
        .then(apidata => {
            return apidata
        });
}

async function ActivateAccount(EmailId, ObjectUid) {
    return CallApi(`/api/Account/AccountActivate`, JSON.stringify({ EmailId, ObjectUid }))
        .then(apidata => {
            return apidata
        });
}

async function Save_Update_ReferralURL(RefId, EndPoint, UserId) {
    return CallApi(`/api/Referral/Save_Update_ReferralURL`, JSON.stringify({ RefId, EndPoint, UserId }))
        .then(apidata => {
            return apidata
        });

}

async function GetReferralURLList(UserName, UserId) {
    return CallApi(`/api/Referral/GetRefferalURL`, JSON.stringify({ UserName, UserId }))
        .then(apidata => {
            return apidata
        });
}

async function Delete_ReferralURL(RefId, EndPoint, UserId) {
    return CallApi(`/api/Referral/Delete_ReferralURL`, JSON.stringify({ RefId, EndPoint, UserId }))
        .then(apidata => {
            return apidata
        });

}
async function Validate_ReferralURL(RefId, EndPoint, UserId) {
    return CallApi(`/api/Referral/Validate_ReferralURL`, JSON.stringify({ RefId, EndPoint, UserId }))
        .then(apidata => {
            return apidata
        });

}

async function PricingToolInitialStatus(UserId, SubDomain, GroupNumber, Task_Name) {
    return CallApi(`/api/Account/GetPricingToolUpdate`, JSON.stringify({ UserId, SubDomain, GroupNumber, Task_Name }))
        .then(apidata => {
            return apidata
        });
}

async function getToken(code, name) {
    return CallApi(`/api/Account/GetToken`, JSON.stringify({ code, name }))
        .then(apidata => {
            return apidata
        });
}

async function getTokenBased_UserInfo(code, name) {
    return CallApi(`/api/Account/Get_TokenBased_UserInfo`, JSON.stringify({ code, name }))
        .then(apidata => {
            try {
                if (apidata.length > 0) {
                    localStorage.setItem('ajs_user_id', window.btoa(apidata[0].id + ':' + apidata[0].userName));
                }
                else {
                    this.logout();
                }
            }
            catch (error) {
                this.logout();
            }
            return apidata
        });
}


async function GetGroupNumberList(UserId, UserName) {
    return CallApi(`/api/Account/Get_UserGroups`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}

async function GetReferralList(UserId, UserName) {
    return CallApi(`/api/Account/Get_UserReferrals`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserSummaryDetail(UserId, UserName) {
    return CallApi(`/api/Account/User_Summary`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function SetUserAccountInfo(UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode) {
    return CallApi(`/api/Account/Set_UserAccountInfo`, JSON.stringify({ UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserAccountInfo(UserId, UserName) {
    return CallApi(`/api/Account/Get_UserAccountInfo`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}


async function ResetPassword(UserId, UserName, Current_Pwd, New_Pwd, Confirm_New_Pwd) {
    return CallApi(`/api/Account/ResetPassword`, JSON.stringify({ UserId, UserName, Current_Pwd, New_Pwd, Confirm_New_Pwd }))
        .then(apidata => {
            return apidata
        });
}


async function FaqList(UserId, UserName) {
    return CallApi(`/api/Account/Faqlist`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}

async function GroupList(UserId, UserName) {
    return CallApi(`/api/Account/Grouplist`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}

async function GetRepsOverrides(UserId, UserName) {
    return CallApi(`/api/Account/GetRepsOverrides`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function GetOverridesOfUser(UserId, UserName, RepId, gn) {
    return CallApi(`/api/Account/GetOverridesOfUser`, JSON.stringify({ UserId, UserName, RepId, gn }))
        .then(apidata => {
            return apidata
        });
}

async function groupengae(UserId, UserName, gn, un, comm, uid, overrideJson) {
    return CallApi_withheader(`/api/Account/gn_engage`, JSON.stringify({ UserId, UserName, gn, un, comm, uid }), overrideJson)
        .then(apidata => {
            return apidata
        });
}

async function resetgroup(UserId, UserName, gn) {
    return CallApi(`/api/Account/ResetGN`, JSON.stringify({ UserId, UserName, gn }))
        .then(apidata => {
            return apidata
        });
}

async function resetprimarygroup(UserId, UserName, gn, un) {
    return CallApi(`/api/Account/ResetPrimaryGN`, JSON.stringify({ UserId, UserName, gn, un }))
        .then(apidata => {
            return apidata
        });
}

async function GetUserList(UserId, UserName, RepId) {
    return CallApi(`/api/Account/ListOfUser`, JSON.stringify({ UserId, UserName, RepId }))
        .then(apidata => {
            return apidata
        });
}

async function DetailOfUser(UserId, UserName, RepId) {
    return CallApi(`/api/Account/DetailOfUser`, JSON.stringify({ UserId, UserName, RepId }))
        .then(apidata => {
            return apidata
        });
}

async function UpdateUserDetail(UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode, Commission, OverrideCommission, GranchildCommission, IsAllowedForGC) {
    return CallApi(`/api/Account/UpdateUserDetail`, JSON.stringify({ UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode, Commission, OverrideCommission, GranchildCommission, IsAllowedForGC }))
        .then(apidata => {
            return apidata
        });
}
async function UpdateGroupNumber(UserId, UserName, GroupNumber, Action) {
    return CallApi(`/api/Account/UpdateGroupNumber`, JSON.stringify({ UserId, UserName, GroupNumber, Action }))
        .then(apidata => {
            return apidata
        });
}

async function updateuserpassword(UserId, UserName, RepId, UserPwd) {
    return CallApi(`/api/Account/ResetUserAccPassword`, JSON.stringify({ UserId, UserName, RepId, UserPwd }))
        .then(apidata => {
            return apidata
        });
}

async function UpdateUserStatus(UserId, UserName, RepId, Action) {
    return CallApi(`/api/Account/UpdateUserStatus`, JSON.stringify({ UserId, UserName, RepId, Action }))
        .then(apidata => {
            return apidata
        });
}
async function GetParentChild(UserId, UserName, RepId) {
    return CallApi(`/api/Account/GetParentChild`, JSON.stringify({ UserId, UserName, RepId }))
        .then(apidata => {
            return apidata
        });
}
async function DeactivateParentChild(UserId, UserName, RepId, pcid, usertype, groupnumber) {
    return CallApi(`/api/Account/DeactivateParentChild`, JSON.stringify({ UserId, UserName, RepId, pcid, usertype, groupnumber }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserGroups(UserId, UserName) {
    return CallApi(`/api/Account/GetUserGroups`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function GetConsumerGroups(UserId, UserName) {
    return CallApi(`/api/Consumer/GetConsumerGroups`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function GetPricingToolRequest(UserId, UserName) {
    return CallApi(`/api/Account/GetPricingToolRequest`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function ManagePricingTool(UserId, UserName, RepId, RequestId, RequestType) {
    return CallApi(`/api/Account/ManagePricingTool`, JSON.stringify({ UserId, UserName, RepId, RequestId, RequestType }))
        .then(apidata => {
            return apidata
        });
}
async function SubmitQuestion(UserName, UserEmail, Message) {
    return CallApi(`/api/Account/AcceptQuest`, JSON.stringify({ UserName, UserEmail, Message }))
        .then(apidata => {
            return apidata
        });
}
async function pwdresetlink(EmailId, ObjectUid) {
    return CallApi(`/api/Account/pwdresetlink`, JSON.stringify({ EmailId, ObjectUid }))
        .then(apidata => {
            return apidata
        });
}
async function validatepwdresetlink(requesturl, objectid) {
    return CallApi(`/api/Account/validatepwdresetlink`, JSON.stringify({ requesturl, objectid }))
        .then(apidata => {
            return apidata
        });
}
async function updatepwd_bylink(useremail, requesturl, pwd, confirmpwd) {
    return CallApi(`/api/Account/updatepwd_bylink`, JSON.stringify({ useremail, requesturl, pwd, confirmpwd }))
        .then(apidata => {
            return apidata
        });
}
async function verifytuser(AccessCode, UserName) {
    return CallApi(`/api/Account/verifytuser`, JSON.stringify({ AccessCode, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function updatetuser(UserId, UserName, AccessCode, Fname, Lname, Email, Phone, Password) {
    return CallApi(`/api/Account/updatetuser`, JSON.stringify({ UserId, UserName, AccessCode, Fname, Lname, Email, Phone, Password }))
        .then(apidata => {
            return apidata
        });
}

async function submit_referral_link_request(UserId, UserName) {
    return CallApi(`/api/Account/Submit_Referral_Request`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function Approve_referral_link_request(UserId, UserName, status) {
    return CallApi(`/api/Account/SP_Update_ReferralRequest`, JSON.stringify({ UserId, UserName, status }))
        .then(apidata => {
            return apidata
        });
}

//----------09-11-2020
async function submit_docusign(issigned, userid, signdetail) {
    return CallApi(`/api/Account/submit_docusign`, JSON.stringify({ issigned, userid, signdetail }))
        .then(apidata => {
            return apidata
        });
}

async function Get_User_ReffralLinks(UserName, UserId) {
    return CallApi(`/api/Account/GetUserReffralLinks`, JSON.stringify({ UserName, UserId }))
        .then(apidata => {
            return apidata
        });
}

async function Update_User_ReffralLinks(linkid, userid, status) {
    return CallApi(`/api/Account/UpdateUserReffralLinks`, JSON.stringify({ linkid, userid, status }))
        .then(apidata => {
            return apidata
        });
}
async function Referral_Reject(UserId, UserName) {
    return CallApi(`/api/Account/Referral_Reject`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
//----23-11-2020
async function ReferLinkUser(UserId, UserName, RepId) {
    return CallApi(`/api/Account/ReferLinkUser`, JSON.stringify({ UserId, UserName, RepId }))
        .then(apidata => {
            return apidata
        });
}
//27-02-2021 this one will call by admin end
async function AddNewUser(Fname, Lname, Phone, Email) {
    return CallApi(`/api/Account/AddNewUser`, JSON.stringify({ Fname, Lname, Phone, Email }))
        .then(apidata => {
            return apidata
        });
}
async function verifyuser_organic(AccessCode, UserName, PassCode) {
    return CallApi(`/api/Account/verifyuser_organic`, JSON.stringify({ AccessCode, UserName, PassCode }))
        .then(apidata => {
            return apidata
        });
}
async function update_orguser(UserId, UserName, AccessCode, PassCode, Fname, Lname, Email, Phone, Password) {
    return CallApi(`/api/Account/update_orguser`, JSON.stringify({ UserId, UserName, AccessCode, PassCode, Fname, Lname, Email, Phone, Password }))
        .then(apidata => {
            return apidata
        });
}
async function CheckPricingtool(groupno) {
    return CallApi(`/api/Account/CheckPricingtool`, JSON.stringify({ groupno }))
        .then(apidata => {
            return apidata
        });
}
async function claimdashboard(Userid, UserName) {
    return CallApi(`/api/Account/Claimdashboard`, JSON.stringify({ Userid, UserName }))
        .then(apidata => {
            try {
                if (apidata.length > 0) {
                    if (apidata[0].code !== "-1") {
                        localStorage.setItem('wt19:awaydpset', window.btoa(apidata[0].objectUid));
                    }
                }
            }
            catch (error) {
            }
            return apidata
        });
}
async function GetPaymentStuff(apptype) {
    return CallApi(`/api/Payment/sqmetadata`, JSON.stringify({ apptype }))
        .then(apidata => {
            return apidata
        });
}
async function SetTandCUpdate(UserId, IsTandCAccepted) {
    return CallApi(`/api/Account/SetTandC`, JSON.stringify({ UserId, IsTandCAccepted }))
        .then(apidata => {
            return apidata
        });
}

async function GetTandCUpdate(UserId, IsTandCAccepted) {
    return CallApi(`/api/Account/GetTandC`, JSON.stringify({ UserId, IsTandCAccepted }))
        .then(apidata => {
            return apidata
        });
}
async function CreatePayment(UserId, idempotency_key, source_id, Fname, Lname, Address1, Address2, State, City, Zip, Email) {
    return CallApi(`/api/Payment/CreatePayment`, JSON.stringify({ UserId, idempotency_key, source_id, Fname, Lname, Address1, Address2, State, City, Zip, Email }))
        .then(apidata => {
            return apidata
        });
}
async function GoogleSheetEntry(apptype) {
    try {
        return CallApi(`/api/Payment/GoogleSheetEntry`, JSON.stringify({ apptype }))
            .then(apidata => {
                return apidata
            });
    }
    catch (err) {
    }
}
async function GetApprovePayment(userid, username, email, approvepayment) {
    return CallApi(`/api/Account/GetApprovePayment`, JSON.stringify({ userid, username, email, approvepayment }))
        .then(apidata => {
            return apidata
        });
}
async function GetBinList(userid, username) {
    return CallApi(`/api/admin/GetBinList`, JSON.stringify({ userid, username }))
        .then(apidata => {
            return apidata
        });
}
async function GetGroupNoTransaction(FDate, TDate, BIN, userid) {
    return CallApi(`/api/admin/GetGroupNoTransaction`, JSON.stringify({ FDate, TDate, BIN, userid }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserTransactionByBin(FDate, TDate, BIN, userid) {
    return CallApi(`/api/admin/GetUserTransactionByBin`, JSON.stringify({ FDate, TDate, BIN, userid }))
        .then(apidata => {
            return apidata
        });
}
async function ResendAccountActivationEmail(Userid, UserName) {
    return CallApi(`/api/admin/ResendAccActivationEmail`, JSON.stringify({ Userid, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function Get_UserTierProperties(Userid) {
    return CallApi(`/api/Account/Get_UserTierProperties`, JSON.stringify({ Userid }))
        .then(apidata => {
            return apidata
        });
}

async function GetListOfMonth(Userid, Userrole) {
    return CallApi(`/api/admin/GetListOfMonth`, JSON.stringify({ Userid, Userrole }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserTierReport(Month) {
    return CallApi(`/api/admin/GetUserTierReport`, JSON.stringify({ Month }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserNameFilter(username) {
    return CallApi(`/api/Account/GetUserNameFilter`, JSON.stringify({ username }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserTransaction(UserId, DateFilter) {
    return await CallApi(`/api/Account/GetUserTransaction`, JSON.stringify({ UserId, DateFilter }))
        .then(apidata => {
            return apidata
        });
}
async function GetPhoneNoValidate(PhoneNumber) {
    return await CallApi(`/api/Account/GetPhoneNoValidate`, JSON.stringify({ PhoneNumber }))
        .then(apidata => {
            return apidata
        });
}
async function GetTransaction6m() {
    return await CallApi(`/api/Report/GetTransaction6m`)
        .then(apidata => {
            return apidata
        });
}
async function CreateNewUserByAdmin(Fname, Lname, Phone, Email, UserName, Password) {
    return await CallApi(`/api/admin/CreateNewUserByAdmin`, JSON.stringify({ Fname, Lname, Phone, Email, UserName, Password }))
        .then(apidata => {
            return apidata
        });
}
async function SaveSecondLink(UserId, UserName, RepId, LinkType, LinkSuffix, GroupNumberId) {
    return await CallApi(`/api/SecondLink/SaveSecondLink`, JSON.stringify({ UserId, UserName, RepId, LinkType, LinkSuffix, GroupNumberId }))
        .then(apidata => {
            return apidata
        });
}
async function GetSecondLink(UserId, UserName) {
    return await CallApi(`/api/SecondLink/GetSecondLink`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function EditSecondLink(UserId, UserName, ObjectUid, LinkSuffix, Flag, action) {
    return await CallApi(`/api/SecondLink/EditSecondLink`, JSON.stringify({ UserId, UserName, ObjectUid, LinkSuffix, Flag, action }))
        .then(apidata => {
            return apidata
        });
}
async function ValidateSecondLink(UserId, UserName, SecondLink) {
    return await CallApi(`/api/SecondLink/ValidateSecondLink`, JSON.stringify({ UserId, UserName, SecondLink }))
        .then(apidata => {
            return apidata
        });
}
async function GetTypeaheadUsername(UserId, UserName, stext) {
    return await CallApi(`/api/SecondLink/GetTypeaheadUsername`, JSON.stringify({ UserId, UserName, stext }))
        .then(apidata => {
            return apidata
        });
}
async function Signup_secLink(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid) {
    return CallApi(`/api/Account/CreateAccount_secLink`, JSON.stringify({ Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid }))
        .then(apidata => {
            return apidata
        });
}
async function ActivateAccount_secLink(EmailId, ObjectUid) {
    return CallApi(`/api/Account/AccountActivate_secLink`, JSON.stringify({ EmailId, ObjectUid }))
        .then(apidata => {
            return apidata
        });
}
async function GetUserReferalBonus(UserId) {
    return CallApi(`/api/Account/GetUserReferalBonus`, JSON.stringify({ UserId }))
        .then(apidata => {
            return apidata
        });
}

async function UpdateTierCommissionSetting(UserId, UserName, UserEmail, TierCommissionSetting) {
    return CallApi(`/api/admin/UpdateTierCommissionSetting`, JSON.stringify({ UserId, UserName, UserEmail, TierCommissionSetting }))
        .then(apidata => {
            return apidata
        });
}
async function TrainingMedia(UserId, UserName,Module) {
    return CallApi(`/api/admin/TrainingModule`, JSON.stringify({ UserId, UserName, Module }))
        .then(apidata => {
            return apidata
        });
}
async function CreateModule(UserId, UserName, moduleName) {
    return CallApi(`/api/admin/CreateModule`, JSON.stringify({ UserId, UserName, moduleName }))
        .then(apidata => {
            return apidata
        });
}
async function EditModule(UserId, UserName, moduleName, moduleId) {
    return CallApi(`/api/admin/EditModule`, JSON.stringify({ UserId, UserName, moduleName, moduleId }))
        .then(apidata => {
            return apidata
        });
}
async function DeleteModule(moduleId) {
    return CallApi(`/api/admin/DeleteModule`, JSON.stringify({ moduleId }))
        .then(apidata => {
            return apidata
        });
}
async function AddMedia(UserId, UserName, moduleid, title, link) {
    return CallApi(`/api/admin/AddMedia`, JSON.stringify({ UserId, UserName, moduleid, title, link }))
        .then(apidata => {
            return apidata
        });
}
async function EditMedia(UserId, UserName, moduleid, title, link, mediaid) {
    return CallApi(`/api/admin/EditMedia`, JSON.stringify({ UserId, UserName, moduleid, title, link, mediaid }))
        .then(apidata => {
            return apidata
        });
}
async function DeleteMedia(UserId, UserName, moduleid, title, link, mediaid) {
    return CallApi(`/api/admin/DeleteMedia`, JSON.stringify({ UserId, UserName, moduleid, title, link, mediaid }))
        .then(apidata => {
            return apidata
        });
}

async function GetMonthYear(UserId, UserName) {
    return CallApi(`/api/admin/GetMonthYear`, JSON.stringify({ UserId, UserName}))
        .then(apidata => {
            return apidata
        });
}
async function SecondaryCommTracking(Date_Month) {
    return CallApi(`/api/admin/SecondaryCommTracking`, JSON.stringify({ Date_Month }))
        .then(apidata => {
            return apidata
        });
}
async function SecondaryCommTrackingSubDist(Date_Month, Distributor_UserId) {
    return CallApi(`/api/admin/SecondaryCommTrackingSubDist`, JSON.stringify({ Date_Month, Distributor_UserId}))
        .then(apidata => {
            return apidata
        });
}
async function SecondaryCommTracking_Tier_NonTier(Date_Month) {
    return CallApi(`/api/admin/SecondaryCommTracking_Tier_NonTier`, JSON.stringify({ Date_Month }))
        .then(apidata => {
            return apidata
        });
}
async function SecondaryCommTrackingSubDist_Tier_NonTier(Date_Month, Distributor_UserId) {
    return CallApi(`/api/admin/SecondaryCommTrackingSubDist_Tier_NonTier`, JSON.stringify({ Date_Month, Distributor_UserId }))
        .then(apidata => {
            return apidata
        });
}
async function Swap_Media(UserId, UserName, ModuleId, SwappingJson) {
    return SwapMedia_withheader(`/api/admin/GetMediaSwap`, JSON.stringify({ UserId, UserName, ModuleId }), SwappingJson)
        .then(apidata => {
            return apidata
        });
}
async function Swap_Module(UserId, UserName, SwappingJson) {
    return SwapModule_withheader(`/api/admin/GetModuleSwap`, JSON.stringify({ UserId, UserName }), SwappingJson)
        .then(apidata => {
            return apidata
        });
}
async function GetUserOverride(UserName, UserId) {
    return CallApi(`/api/Account/GetUserReferral`, JSON.stringify({ UserName, UserId }))
        .then(apidata => {
            return apidata
        });
}
async function GetTransaction(FDate,TDate) {
    return await CallApi(`/api/Report/GetTransaction`, JSON.stringify({ FDate, TDate }))
        .then(apidata => {
            return apidata
        });
}
async function GetParentReferral(FDate, TDate) {
    return await CallApi(`/api/Report/GetParentReferral`, JSON.stringify({ FDate, TDate }))
        .then(apidata => {
            return apidata
        });
}

async function GetUsetLogin(Username, Password) {
    return await CallApi(`/api/User/LoginAsync`, JSON.stringify({ Username, Password }))
        .then(apidata => {
            return apidata
        });
}

async function GetUserReps(Userid) {
    return await CallApi(`/api/Account/GetUserReps`, JSON.stringify({ Userid}))
        .then(apidata => {
            return apidata
        });
}

async function GetListOfMonthPayout(Userid, Userrole) {
    return CallApi(`/api/admin/GetListOfMonthPayout`, JSON.stringify({ Userid, Userrole }))
        .then(apidata => {
            return apidata
        });
}
//-----Consumer integration
async function Summary_Details(Userid, UserName) {
    return CallApi(`/api/Consumer/Summary_Details`, JSON.stringify({ Userid, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function Get_FAQ(Userid, UserName, Count) {
    return CallApi(`/api/Consumer/Get_FAQ`, JSON.stringify({ Userid, UserName, Count }))
        .then(apidata => {
            return apidata
        });
}
async function ConsumerToDistributor(Userid, UserName) {
    return CallApi(`/api/Consumer/Consumer_To_Distributor`, JSON.stringify({ Userid, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function IphoneChromeEmail(Apple_passlink, Email) {
    return CallApi(`/api/Consumer/Iphone_Chrome_Email`, JSON.stringify({ Apple_passlink, Email }))
        .then(apidata => {
            return apidata
        });
}
async function Share_Card(Userid, UserName, JsonEmails) {
    return await fetch('/api/Consumer/Share_Card', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'card_user_emails': JsonEmails },
        body: JSON.stringify({ Userid: Userid, UserName: UserName})
    }).then(handleResponse).then(apidata => {
        return apidata;
    });
}
async function Paid_transaction_box(UserId, MonthYear) {
    return CallApi(`/api/Consumer/Paid_transaction_box`, JSON.stringify({ UserId, MonthYear }))
        .then(apidata => {
            return apidata
        });
}

async function apple_google_wallet_pass_link(URL, bin, pcn, groupnumber, memberid, passtype, appname) {
    return await fetch(URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ bin: bin, pcn: pcn, groupnumber: groupnumber, memberid: memberid, appname: appname, passtype: passtype })
    }).then(handleResponse_SimpleData).then(apidata => {
        return apidata;
    });
}
async function apple_google_wallet_pass_link_New(URL, bin, pcn, groupnumber, memberid, passtype, appname) {
    return  fetch(URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bin: bin, pcn: pcn, groupnumber: groupnumber, memberid: memberid, appname: appname, passtype: passtype })
    }).then(handleResponse_SimpleData).then(apidata => {
        return apidata;
    });
}
//async function ConsumerWiseaccount(UserId, Username) {
//    return CallApi(`/api/WisePaymentConsumer/WiseAccount`, JSON.stringify({ UserId, Username }))
//        .then(apidata => {
//            return apidata
//        });
//}
async function GetAccountNumber(UserId, UserName) {
    return CallApi(`/api/Consumer/GetAccountNumber`, JSON.stringify({ UserId, UserName}))
        .then(apidata => {
            return apidata
        });
}

async function SaveAddress(UserId, Address,  country, state, city, postCode, RoutingNum, AccountNum, AccountType ) {
    return CallApi(`/api/Consumer/SaveAddress`, JSON.stringify({ UserId, Address,  country, state, city, postCode, RoutingNum, AccountNum, AccountType  }))
        .then(apidata => {
            return apidata
        });
}
async function SaveEditAddress(UserId, Address, country, state, city, postCode, RoutingNum, AccountNum, AccountType) {
    return CallApi(`/api/Consumer/EditWiseUser`, JSON.stringify({ UserId, Address, country, state, city, postCode, RoutingNum, AccountNum, AccountType }))
        .then(apidata => {
            return apidata
        });
}
async function DeactivateAccount(UserId, UserName) {
    return CallApi(`/api/Account/DeactivateAccount`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
async function passredirect(code, name) {
    return CallApi(`/api/v1/GetLongURL`, JSON.stringify({ code, name }))
        .then(apidata => {
            return apidata
        });
}
async function pqInvite(UserId, UserName) {
    return CallApi(`/api/Account/pqInvitation`, JSON.stringify({ UserId, UserName }))
        .then(apidata => {
            return apidata
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            return data;
        }
        catch (error) {
            const data = [];
            return data;
        }
    });
}
function handleResponse_SimpleData(response) {
    return response.text().then(text => {
        try {
            return text;
        }
        catch (error) {
            //const data = [];
            return null;
        }
    });
}
function getemail() {
    let show_email = 'support@nulifespanrx.com';
    try {
        let getmail = window.location.href;
        if (getmail.toLowerCase().includes("localhost:") || getmail.toLowerCase().includes("release-backoffice") || getmail.toLowerCase().includes("dev-backoffice")) {
            show_email = "development@nulifespanrx.com";
        }
        else {
            show_email = "support@nulifespanrx.com";
        }
    }
    catch (error) { }
    return show_email;
}
