import React, { Component } from "react";
import Logo from "../../images/logo.png";
class Consumer_header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let url = window.location.origin + '/contact#faqs';
    return (
      <div className="header">
      {!window.opener ?
        <a href="/" className="logosec consumer">
          <img src={Logo} alt="v" />
        </a> : <a className="logosec consumer">
                    <img src={Logo} alt=""/>
                </a>}
     {/*!window.opener ?
        <a className="btn" href={url} target="_blank">
          How it Works?
        </a> : null*/}
      </div>
    );
  }
}
export default Consumer_header;
