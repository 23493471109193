import React, { Component } from "react";
import SingleCare from '../../images/singlecare.png';
import Rxlogo from '../../images/logo-white.png';
import wallmart from '../../images/wallmart-card.png';
import cvs from '../../images/wallmart-cvs.png';
class CardFront extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carddata: {},
            groupdata: ''
        };
    }
    componentWillReceiveProps(props) {
        this.state.carddata = props.cardData ? props.cardData : { bin: '', pcn: '' }
        this.state.groupdata = props.groupData ? props.groupData : { groupnumber: '' }
    }
    render() {
        let datetime = Math.floor(Math.random() * 1000000)
        return (
            <>
                {
                    this.state.carddata && this.state.carddata.classnames === 'cvs_card' ?
                        <>
                            <div className="order_card card_front glic_card cvs_card">
                                <div className="side-icon"></div>
                                <div className="card_header">
                                    <div className="card_heading">
                                        <img src={SingleCare} className="img-fluid card_logo" alt="Siglecare Logo" />
                                        <div className="header_info">For exclusive use at <img src={cvs} alt="CVS Logo" /></div>
                                    </div>
                                    <div className="tagline">Pharmacy Savings Card</div>
                                </div>
                                <div className="card_body">
                                    <div className="prescription-info">Save up to 80% on your prescriptions.</div>
                                    <div className="member-info">
                                        <div className="memberid">Member ID <span>{"AX" + datetime}</span></div>
                                    </div>
                                    <div className="card_info">
                                        <div>Bin: <span>{this.state.carddata.bin}</span></div>
                                        <div>Group: <span>{this.state.groupdata.groupnumber}</span></div>
                                        <div>PCN: <span>{this.state.carddata.pcn}</span></div>
                                    </div>
                                </div>
                                <div className="card_footer">
                                    <div className="footer-left">Show this card to save up to 80% on your prescriptions</div>
                                    <div className="footer-right">
                                        <div className="insurance-text">This card is free. This is not insurance.</div>
                                        <p>Customer Service: 1-800-999-3053</p>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <>{this.state.carddata && this.state.carddata.classnames === 'walmart_card' ?
                            <>
                                <div className="order_card card_front glic_card">
                                    <div className="side-icon"></div>
                                    <div className="card_header">
                                        <div className="card_heading">
                                            <img src={SingleCare} className="img-fluid card_logo" alt="Siglecare Logo" />
                                            <div className="header_info">Pharmacy Savings</div>
                                        </div>
                                        <div className="tagline">Save on your prescription medications</div>
                                    </div>
                                    <div className="card_body">
                                        <div className="prescription-info">Available at<br /><img src={wallmart} alt="Walmart Logo" /></div>
                                        <div className="card_info">
                                            <div>Member ID: <span>{"AX" + datetime}</span></div>
                                            <div>Bin: <span>{this.state.carddata.bin}</span></div>
                                            <div>Group: <span>{this.props.PrimaryGn ? this.props.PrimaryGn : this.state.groupdata.groupnumber}</span></div>
                                            <div>PCN: <span>{this.state.carddata.pcn}</span></div>
                                        </div>
                                    </div>
                                    <div className="card_footer">
                                        <div className="insurance-text">This card is not insurance.</div>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="order_card card_front glic_card">
                                    <div className="side-icon"></div>
                                    <div className="card_header">
                                        <img src={Rxlogo} className="img-fluid card_logo" alt="NuLifeSpan Rx Logo" />
                                        <h2>Prescription Discount Card</h2>
                                    </div>
                                    <div className="card_body">
                                        <div className="member-info">
                                            <div className="memberid">Member ID <span>{'100-4542'}</span></div>
                                            <p>Accepted at 35,000<br />pharmacies nationwide</p>
                                        </div>
                                        <div className="card_info">
                                            <div>Bin: <span>{this.props.bin ? this.props.bin : this.state.carddata.bin}</span></div>
                                            <div>PCN: <span>{this.props.pcn ? this.props.pcn : this.state.carddata.pcn}</span></div>
                                            <div>Group: <span>{this.props.PrimaryGn ? this.props.PrimaryGn : this.state.groupdata.groupnumber}</span></div>
                                        </div>
                                    </div>
                                    <div className="card_footer">
                                        <div className="footer-left">Show this card to save up to 80% on your prescriptions</div>
                                        <div className="footer-right">
                                            <div className="insurance-text">This card is free. This is not insurance.</div>
                                            <p>Customer Service: 1-800-999-3053</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }</>
                }
            </>
        )
    }
}
export default CardFront;