import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Signup_header from "../Register/signup_header";
import { userService } from "../../_services";
import Signupleft from "../../images/signupleft.png";
import mobile_login_bg from "../../images/signup_bg.png";
import signupcheck from "../../images/savecheck.png";
import $ from 'jquery';

class RuserSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            UserName: '',
            Password: '',
            ConfirmPassword: '',
            data_validated: false,
            loading_text: 'Please wait while your request is processing',
            user_id: '',
            access_code: '',
            login_loader: false,
            user_update: false,
            chkbox: false,
            passcode: null,
            errors: {
                Fname_E: '',
                Lname_E: '',
                Phone_E: '',
                Email_E: '',
                UserName_E: '',
                Password_E: '',
                ConfirmPassword_E: ''
            },
        };
        this.noerrors = {
            Fname_E: '',
            Lname_E: '',
            Phone_E: '',
            Email_E: '',
            UserName_E: '',
            Password_E: '',
            ConfirmPassword_E: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
        this.showpassword = this.showpassword.bind(this);
        this.handleChangeChk = this.handleChangeChk.bind(this);
    }
    componentDidMount() {
        let params = new URL(window.location.href).searchParams;
        let token = params.get('accesscode');
        let username = params.get('username');
        let passcode = params.get('passcode');
        this.setState({ access_code: token, passcode: passcode });
        this.verifyuser_organic(token, username, passcode);
    }
    verifyuser_organic(AccessCode, UserName, PassCode) {
        try {
            userService.verifyuser_organic(AccessCode, UserName, PassCode)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].statusMessage === "Success") {
                                let value = apidata[0];
                                this.setState({ Fname: value.fname || '', Lname: value.lname || '', Phone: value.phone || '', Email: value.email || '', UserName: '', data_validated: true, user_id: value.userId || '' })
                            }
                            else {
                                this.setState({ data_validated: false, loading_text: "Invalid Request! Please Click below to signup" })
                            }
                        }
                    }
                    catch (error) {
                        //let errors = this.state.errors;Commented because not in use
                    }
                }
                );
        }
        catch (error) {
            //let errors = this.state.errors;Commented because not in use
        }
    }


    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "Fname" || event.target.name === "Lname") {
            if (event.target.value !== "") {
                var special = /^[a-zA-Z]+$/g;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
    }
    handleChangeChk = (event) => {
        this.setState({ chkbox: !this.state.chkbox });
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
   
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {
            userService.GetUserNameFilter(this.state.UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '0') {
                                errors.UserName_E = apidata[0].message;
                            }
                            if (this.state.UserName) {
                                let str = this.state.UserName;
                                str = str.replace(/^\s+/, '');
                                this.setState({ UserName: str });
                                if (this.state.UserName !== "") {
                                    var special = /^[0-9\b]+$/;
                                    if (this.state.UserName.match(special)) {
                                        errors.UserName_E = 'This Username not allowed';
                                    }
                                }
                            }
                            userService.GetPhoneNoValidate(this.state.Phone)
                                .then(apidata => {

                                    if (apidata[0].code !== "1") {
                                        errors.Phone_E = 'Invalid Phone Number';
                                    }
                                    else {
                                        if (apidata[0].message != null) {
                                            this.setState({ Phone: apidata[0].message })
                                        }
                                    }
                                
                        
                                    if (this.state.Fname.length < 3)
                                        errors.Fname_E = 'First Name must be at least 3 characters long';
                                    if (this.state.Lname.length < 2)
                                        errors.Lname_E = 'Last Name must be at least 2 characters long';

                                    if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10)
                                        errors.Phone_E = 'Phone Number must be 10 digits';
                                    //if (userService.ValidPhone(this.state.Phone) == false && this.state.Phone != "")
                                    //    errors.Phone_E = "Please enter valid Phone Number";
                                    if (this.state.Email.length < 1)
                                        errors.Email_E = "Enter your email";
                                    if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "")
                                        errors.Email_E = 'Invalid Email';
                                    if (this.state.UserName.length < 6)
                                        errors.UserName_E = 'Username must be at least 6 characters long';
                                    if (this.state.UserName.includes("user") || this.state.UserName.includes("rxh") || this.state.UserName.includes("temp"))
                                        errors.UserName_E = 'This Username not allowed';
                                    if (this.state.UserName.includes(' '))
                                        errors.UserName_E = 'Username does not allow space';
                                    if (this.state.Password.length < 6)
                                        errors.Password_E = 'Password must be at least 6 characters long';
                                    if (this.state.Password !== this.state.ConfirmPassword)
                                        errors.ConfirmPassword_E = 'Password & Confim Password must match';

                                    if (this.state.chkbox === true)

                                        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                                            this.setState({ errors: errors });
                                        }
                                        else {
                                            const { Fname, Lname, Phone, Email, UserName, Password, user_id, access_code, passcode } = this.state
                                            this.update_orguser(user_id, UserName, access_code, passcode, Fname, Lname, Email, Phone, Password);
                                        }
                                })}
                    })
        }
        catch (error) {
        }
    }
    update_orguser(UserId, UserName, AccessCode, PassCode, Fname, Lname, Email, Phone, Password) {
        this.setState({ login_loader: true })
        try {
            userService.update_orguser(UserId, UserName, AccessCode, PassCode, Fname, Lname, Email, Phone, Password)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                this.setState({ login_loader: false, user_update: true })
                            }
                            else {
                                let errors = this.state.errors;
                                if (apidata[0].statusMessage === 'Phone no is already exist') {
                                    errors.Phone_E = 'Phone no is already exist';
                                    this.setState({ errors: errors, Phone: "", login_loader: false })
                                }
                                else {
                                    errors.UserName_E = apidata[0].statusMessage;
                                    this.setState({ errors: errors, UserName: "", login_loader: false })
                                }
                            }
                        }
                    }
                    catch (error) {
                        //let errors = this.state.errors;Commented because not in use
                    }
                }
                );
        }
        catch (error) {
            //let errors = this.state.errors;Commented because not in use
        }
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');

    }
    render() {
        const { errors } = this.state;
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) return <Redirect to="/Affiliate_Url" />
        return (
            <div className="container_signup">
                <Signup_header />
                <div>
                    {this.state.user_update === false ?
                        <div className="signupbox_area">
                            <div className="image_area">
                                <img className="mobile_login_bg" src={mobile_login_bg} alt=""/>
                                <img src={Signupleft} alt=""/>

                            </div>
                            {this.state.data_validated === true ?
                                <div className="signuparea">
                                    <div className="fields">
                                        <p>
                                            Set Details
              </p>
                                        <div className={errors.Fname_E.length > 0 ? 'errors' : ''}>
                                            <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>First Name</span>
                                            {errors.Fname_E.length > 0 &&
                                                <span onClick={this.removeError.bind(this)}>{errors.Fname_E} </span>}
                                        </div>
                                        <div className={errors.Lname_E.length > 0 ? 'errors' : ''}>
                                            <input type="text" placeholder=" " name="Lname" value={this.state.Lname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Last Name</span>
                                            {errors.Lname_E.length > 0 &&
                                                <span onClick={this.removeError.bind(this)}>{errors.Lname_E}</span>}
                                        </div>
                                        <div className={errors.Phone_E.length > 0 ? 'errors' : ''}>
                                            <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Phone Number</span>
                                            {errors.Phone_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Phone_E}</span>}
                                        </div>
                                        <div className={errors.Email_E.length > 0 ? 'disable_email_div errors' : 'disable_email_div'}>
                                            <input type="email" placeholder=" " name="Email" value={this.state.Email} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Email</span>
                                            {errors.Email_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Email_E}</span>}
                                        </div>
                                        <p className={errors.UserName_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                            <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Username</span>
                                            {errors.UserName_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.UserName_E} </span>}
                                        </p>
                                        <p className={errors.Password_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                            <i onClick={this.showpassword}></i>
                                            <input type="Password" placeholder=" " name="Password" value={this.state.Password} onChange={this.HandleInput} maxLength={30} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Password</span>
                                            {errors.Password_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Password_E} </span>}

                                        </p>
                                        <p className={errors.ConfirmPassword_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>

                                            <i onClick={this.showpassword}></i>
                                            <input type="Password" placeholder=" " name="ConfirmPassword" value={this.state.ConfirmPassword} onChange={this.HandleInput} maxLength={30} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Confirm Password</span>
                                            {errors.ConfirmPassword_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.ConfirmPassword_E} </span>}
                                        </p>
                                        <p className="terms_chkbox">
                                            <input type="checkbox" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk.bind(this)} id="terms" />
                                            <label for="terms"><div>By clicking submit you agree to our<a href="/terms/" target="_blank" className="linkified"> Terms and Conditions</a>.</div></label>

                                        </p>
                                        {this.state.chkbox ?
                                            <div className={this.state.login_loader ? "save_continue loader widthloader" : 'save_continue'} onClick={this.handle_save_continue_btn.bind(this)}>
                                               Submit
              </div> : <div className="save_continue disabled">
                                               Submit
              </div>}

                                    </div>
                                </div>
                                :
                                <div className="signuparea">
                                    <div className="welcome_signup">
                                        {/*<img src={signupcheck} />*/}
                                        <p>{this.state.loading_text}</p>
                                        {this.state.loading_text !== "Please wait while your request is processing" ?
                                            <NavLink className="btn" to="/signup">Sign Up here</NavLink>
                                            : null}
                                    </div>
                                </div>}
                        </div>
                        :
                        <div className="signupbox_area">
                            <div className="image_area">
                                <img className="mobile_login_bg" src={mobile_login_bg} alt=""/>
                                <img src={Signupleft} alt=""/>

                            </div>
                            <div className="signuparea">
                                <div className="welcome_signup">
                                    <img src={signupcheck} alt=""/>
                                    <p>Updated successfully</p>
                                    <NavLink className="btn" to="/login">Sign In Now</NavLink>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default RuserSetup;
