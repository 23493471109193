import React, { Component } from 'react';
import { Pie, Column } from 'react-chartjs-2';
import Frame from './Frame';
import { Bar } from 'react-chartjs-2';
import moment, { relativeTimeRounding } from 'moment';
import Chart from 'chart.js/auto';
import FlipIcon from '../../images/flip.svg';
import InfoIcon from '../../images/info-icon.svg';
import BarChartIcon from '../../images/bar-chart-icon.svg';
import BarChartIconInactive from '../../images/bar-chart-inactive.svg';
import listingIcon from '../../images/listing-icon.svg';
import listingIconActive from '../../images/listing-icon-active.svg';
import loader from '../../images/loader.gif';
import exportIcon from '../../images/export.svg';
import $ from 'jquery';
import Pagination from "react-js-pagination";
//import ColorJson from '../../src/whitelabel.json';

class MultiStackBar_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            barChartIconInactive: false,
            chartData1: [],
            chartData2: [],
            columnData: [],
            options: {
                maintainAspectRatio: false,
            },
            IsDataLoaded: false,
            XlabelString: this.props.XlabelString,
            YlabelString: this.props.YlabelString,
            ColorCode: this.props.data.ColorCode,
            IFrameId: this.props._Index,
            LegendText1: '',
            LegendValue1: '',
            LegendText2: '',
            LegendValue2: '',
            LegendText3: '',
            LegendValue3: '',
            WidgetId: this.props.data.id,
            activePage: 1,
            negativeValues: false,
            noDataDisplay: '',
            sumOfLegendValue: ''
        };
    }
    handleFlip(event) {
        $(event.target).parent().next().toggleClass('flipped');
        $(event.target).toggleClass('active');
        $(event.target).parent().toggleClass('active');
    }
    ExportWidget = (event) => {
        try {
            let widgetTitle = '';
            var data = "";
            var tableData = [];
            try { widgetTitle = event.target.getAttribute('widget-title'); } catch (error) { }
            for (var i = 0; i < this.state.columnData.length; i++) {
                var rowData = [];
                for (var j = 0; j < this.state.columnData[i]["datasets"].length; j++) {
                    if ((widgetTitle == "Client Access Link Signups" && j == 0))
                        rowData.push(' ' + this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                    else
                        rowData.push(this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                }
                tableData.push(rowData.join(","));
            }
            data += tableData.join("\n");
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', $(event.target).attr('widget-title') + '.csv');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) { }
    }
    componentDidUpdate(prevProp) {
        if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "MultiStackBar_List") {
            //console.log("update Column", this.props)
            this.fetchData_locally();
        }
    }
    componentDidMount() {
        if (this.props.data.type == "MultiStackBar_List") {
            // this.setState({ data2: { labels: ['Red', 'Blue', 'Yellow'], datasets: [{ data: [300, 50, 100] }] } })
            this.fetchData_locally();
        }
        //window.Chart.defaults.global.defaultFontFamily = `'Fira Sans', sans-serif`;

        // default toggle 
        $('#WMetric1a1 .flip_icon').parent().next().toggleClass('flipped');
        $('#WMetric1a1 .flip_icon').toggleClass('active');
        $('#WMetric1a1 .flip_icon').parent().toggleClass('active');

        $('#DMetric1a1 .flip_icon').parent().next().toggleClass('flipped');
        $('#DMetric1a1 .flip_icon').toggleClass('active');
        $('#DMetric1a1 .flip_icon').parent().toggleClass('active');
    }
    Randomcolor_Random() {
        return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    }
    Randomcolor(i) {

        //return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
        if (i % 10 == 0) {
            return '#39D890';
        } else if (i % 9 == 0) {
            return '#39D7D8';
        } else if (i % 8 == 0) {
            return '#FD775C';
        } else if (i % 7 == 0) {
            return '#80E166';
        } else if (i % 6 == 0) {
            return '#6AC7F1';
        } else if (i % 5 == 0) {
            return '#0282FB'
        } else if (i % 4 == 0) {
            return '#CB62FF'
        } else if (i % 3 == 0) {
            return '#7F84E8'
        } else if (i % 2 == 0) {
            return '#FCE048'
        } else {
            return '#FFBA10'
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    mobileMenu(event) {
        $('.flip-icons').hide();
        $('.export_icon').hide();
        $('.mobileExportLabel').hide();
        if ($(event.target).parents('.flip-section').children('.flip-icons').hasClass('active')) {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_bar_icon').show();
            $('.mobileBarLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobile_list_icon').hide();
            $('.mobileListLabel').hide();
        } else {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_list_icon').show();
            $('.mobileListLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobile_bar_icon').hide();
            $('.mobileBarLabel').hide();
        }
    }
    handleBarList(event) {
        if ($(window).width() < 850) {
            event.stopPropagation();
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').parent().next().toggleClass('flipped');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').toggleClass('active');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').parent().toggleClass('active');
            $(event.target).parents('.flip-section').children('.flip-icons').hide();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').hide();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').hide();
        }
    }
    fetchData_locally() {
        let data_available = '';
        this.setState({
            IsDataLoaded: false,
            noDataDisplay: 'Loading. Please wait.'
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                try {

                    //--------------------------------Bar Chart
                    if (apidata.length > 0) {
                        try {
                            this.setState({ LegendText1: apidata[0].legend[0].legendText, LegendValue1: apidata[0].legend[0].legendValue, LegendText2: apidata[0].legend[1].legendText, LegendValue2: apidata[0].legend[1].legendValue, LegendText3: apidata[0].legend[2].legendText, LegendValue3: apidata[0].legend[2].legendValue, sumOfLegendValue: apidata[0].sumOfLegendValues[0].sumOfLegendValue, activePage: 1, columnData: apidata[0].listDataSet});
                            // data not available
                            data_available = apidata[0].columnDataSet1[0].data.length <= 1 && apidata[0].columnDataSet2[0].data.length <= 1 ? 'No Data Available' : '';
                        }
                        catch (error) {
                        }
                    }

                    let barHover = '#EE934A';
                    let wlBgColor = '#000000';

                    // check for negative values
                    apidata[0].columnDataSet1[0].data.every((item) => {
                        if (item.y < 0) {
                            this.setState({ negativeValues: true });
                            return false;
                        } else {
                            return true;
                        }
                    });

                    var DistData = {
                        label: apidata[0].columnDataSet1[0].toplabel,
                        data: apidata[0].columnDataSet1[0].data,
                        backgroundColor: wlBgColor,
                        borderColor: wlBgColor,
                        stack: '1',
                        BorderRadius: this.state.BorderRadius,
                        hoverBackgroundColor: "#000"
                    };

                    var SubDistData = {
                        label: apidata[0].columnDataSet2[0].toplabel,
                        data: apidata[0].columnDataSet2[0].data,
                        backgroundColor: this.state.ColorCode,
                        borderColor: this.state.ColorCode,
                        stack: '1',
                        hoverBackgroundColor: barHover,
                        BorderRadius: this.state.BorderRadius
                    };
                    var BonusData = {
                        label: apidata[0].columnDataSet4[0].toplabel,
                        data: apidata[0].columnDataSet4[0].data,
                        backgroundColor: barHover,
                        borderColor: barHover,
                        stack: '1',
                        hoverBackgroundColor: "#44b672",
                        BorderRadius: this.state.BorderRadius
                    };
                    var leftPosition;
                    var cOverride = this.state.LegendText2;
                    this.setState({
                        ...this.state,
                        noDataDisplay: data_available,
                        IsDataLoaded: true,
                        chartData1: [
                            {
                                labels: apidata[0].columnDataSet1[0].labels,
                                datasets: [DistData, SubDistData, BonusData]
                            }
                        ],
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            plugins: {
                                legend: {
                                    labels: {
                                        fontColor: '#8ba6c1)'
                                    },
                                    display: false
                                },
                                tooltip: {
                                    // Disable the on-canvas tooltip
                                    enabled: false,

                                    external: function (context) {
                                        // Tooltip Element
                                        let tooltipEl = document.getElementById('chartjs-tooltip');

                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table style="background: #fff; margin-left: calc(-50% - 6px); position: relative; z-index: 99999; font-weight: 600; border: 2px solid #B6ACA2; border-radius: 6px; padding: 10px; line-height: 28px; font-family: Urbanist, sans-serif; color: #000000; white-space: nowrap; font-size: 17px; text-align: left; display: block; width: 155px;"></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        const tooltipModel = context.tooltip;
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            tooltipEl.style.display = 'none';
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }

                                        const position = context.chart.canvas.getBoundingClientRect();
                                        let leftPercent = parseInt((parseInt(tooltipModel.caretX) * 100) / position.width);
                                        let reduceWidth = parseInt(parseInt(tooltipModel.width) / 2);

                                        // Set Text
                                        if (tooltipModel.body) {
                                            const titleLines = tooltipModel.title || [];
                                            const bodyLines = tooltipModel.body.map(getBody);
                                            let innerHtml = '<thead>';

                                            titleLines.forEach(function (title) {
                                                innerHtml += '<tr><th style="font-size: 15px; line-height: 18px; padding: 0px;display: block; font-weight: normal;">' + title + '</th></tr>';
                                            });
                                            innerHtml += '</thead><tbody>';

                                            bodyLines.forEach(function (body, i) {
                                                const colors = tooltipModel.labelColors[i];

                                                if (leftPercent > 94) {
                                                    var span = '<span style="position: absolute; bottom: -7px; right: 0; margin-right: 0px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                                } else {
                                                    var span = '<span style="position: absolute; bottom: -7px; left: 50%; margin-left: -3px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                                }
                                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                            });
                                            innerHtml += '</tbody>';

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }

                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.display = 'block';
                                        tooltipEl.style.position = 'absolute';

                                        if ($(window).width() < 980) {
                                            setTimeout(() => {
                                                tooltipEl.style.display = 'none';
                                            }, 3000)
                                        }
                                        tooltipEl.style.position = 'absolute';
                                        if (leftPercent > 94) {
                                            tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + -reduceWidth + -6 + 'px';
                                        } else {
                                            tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + 'px';
                                        }
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + -75 + 'px';
                                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                }
                            },
                            scales: {
                                y: {
                                    title: {
                                        display: true,
                                        text: this.props.data.YlabelString,
                                        color: '#B6ACA2',
                                        font: {
                                            size: 13
                                        }
                                    },
                                    ticks: this.state.negativeValues ? {
                                        display: true,
                                        color: "#B6ACA2",
                                        font: {
                                            size: 13
                                        },
                                        beginAtZero: true,
                                        min: -2,
                                        max: 4
                                    } : {
                                        color: "#B6ACA2",
                                        font: {
                                            size: 13
                                        },
                                        beginAtZero: true
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false
                                    },
                                },
                                x: {
                                    offset: true,
                                    barThickness: 15,
                                    maxBarThickness: 15,
                                    minBarLength: 3,
                                    ticks: {
                                        display: false,
                                        autoSkip: true,
                                        maxTicksLimit: 5,
                                        color: "#B6ACA2",
                                        font: {
                                            size: 13
                                        },
                                        family: 'Urbanist, sans-serif'
                                    },
                                    grid: {
                                        lineWidth: 0,
                                        display: false,
                                        drawBorder: false
                                    },
                                    title: {
                                        display: true,
                                        text: this.props.data.XlabelString,
                                        color: '#B6ACA2',
                                        font: {
                                            size: 13
                                        }
                                    }
                                }
                            }
                        }
                    });

                    //---------------------------------List Chart
                    try {

                        //this.setState({
                        // ... this.state ,columnData: apidata[0].listDataSet
                        //});
                    }
                    catch (error) { }

                }
                catch (error) {
                }
            });
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }
    handleBar(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().next().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }
    handleList(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().prev().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }
    titleHover = e => {
        if (this.isEllipsisActive(e.target)) {
            e.target.parentElement.classList.add("hover-on");
        }
    }
    isEllipsisActive = (element) => {
        return element.offsetWidth < element.scrollWidth;
    }

    render() {
        var tooltipColor1 = "#000000";
        const tooltipColor2 = this.props.data.ColorCode;
        var tooltipColor3 = "#EE934A";
        if (window.location.host.split('.').slice(-2).join('.').toLowerCase() == "nulifespanrx.com" || window.location.host.split('.').slice(-2).join('.').toLowerCase() == "localhost:5464" || window.location.host.toLowerCase() == "tempfilter.glichealth.com") {
            tooltipColor1 = "#000000";
            tooltipColor3 = "#F1857F";
        } else {
            tooltipColor1 = "#000000";
            tooltipColor3 = "#F1857F";
        }
        let a1 = this.state.LegendText1;
        let a2 = this.state.LegendText2;
        const userlistobj = JSON.parse(JSON.stringify(this.state.columnData));
        userlistobj.splice(0, 1);
        let paging = 0;
        paging = userlistobj.length % 6 != 0 ? userlistobj.length + 6 - userlistobj.length % 6 : userlistobj.length;

        return (
            <div className="bar">
                {this.state.IsDataLoaded ? '' : (<div className="loader-section"><div className="theme-loader"></div></div>)}
                <div className="flip-section">
                    <div className="widget-head mobile-position">
                        <h3>
                            <a>
                                {
                                    this.props.data.title != "Client Access Link Signups" && this.props.data.title != "New vs Repeat Transactions" ?
                                        <span>$ {this.state.sumOfLegendValue + " "}</span> :
                                        <span>{this.state.sumOfLegendValue + " "}</span>
                                }
                                <span className="transition-title" data-title={this.props.data.title}><label onMouseOver={this.titleHover}>{this.props.data.title}</label><p>{this.props.data.Subtitle}</p></span>
                            </a>
                        </h3>
                    </div>
                    <a href="javascript:void('0')" widget-id={this.props.data.id} widget-title={this.props.data.title} onClick={this.ExportWidget} className='onlyList custom-tooltip' id={this.props.data.title}><img className="export_icon" widget-title={this.props.data.title} widget-id={this.props.data.id} src={exportIcon} alt="Export Icon" /><span className="hover-tooltip down-report">Download report</span></a>
                    <label className="mobileExportLabel" widget-title={this.props.data.title} widget-id={this.props.data.id} onClick={this.ExportWidget}>Export</label>
                    {/*<div className="transitions">
                        <div className="left"><span className="rectangle" style={{ background: tooltipColor }}>&nbsp;</span><span className="bold">{this.state.LegendValue}</span></div>
                        <div className="right">
                            <span>{this.state.LegendText}</span>
                        </div>
                    </div>*/}
                    <a className="mobileListIcon" href="javascript:void('0')" onClick={this.mobileMenu}>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                    </a>
                    <div className="flip-icons">
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img className="bar_chart_icon" src={BarChartIcon} alt="barcharticon" />
                            <img className="mobile_bar_icon" src={BarChartIcon} alt="barcharticon" />
                            <label onClick={this.handleBarList} className="mobileBarLabel">Bar</label>
                            <img className="bar_chart_icon inactive" onClick={this.handleBar} src={BarChartIconInactive} alt="barcharticoninactive" />
                            <span className="hover-tooltip bar">Bar</span></a>
                        <a href="javascript:void('0')" onClick={this.handleFlip} className="flip_icon">Flip</a>
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img className="listing-icon" onClick={this.handleList} src={listingIcon} alt="listingicon" />
                            <img onClick={this.handleBarList} className="listing-icon activeicon" src={listingIconActive} alt="listcharticonactive" />
                            <img onClick={this.handleBarList} className="mobile_list_icon" src={listingIconActive} alt="listcharticonactive" />
                            <span className="hover-tooltip list">List</span>
                            <label className="mobileListLabel" onClick={this.handleBarList}>List</label>
                        </a>
                    </div>
                    <div className="flip-bg multibar" id={this.state.IFrameId + "_flip_bg_frame"}>
                        <div className="front">
                            <div className="first">
                                {
                                    this.state.chartData1.length > 0 && this.state.chartData1[0].labels.length > 0 && this.state.chartData1 != null ?
                                        <div>
                                            <div className="transitions">
                                                <div className="same-transitions">
                                                    <div className="left"><span className="rectangle" style={{ background: tooltipColor1 }}>&nbsp;</span><span className="bold">{this.state.LegendValue1}</span></div>
                                                    <div className="right">
                                                        <span>{this.state.LegendText1}</span>
                                                    </div>
                                                </div>
                                                <div className="same-transitions">
                                                    <div className="left"><span className="rectangle" style={{ background: tooltipColor2 }}>&nbsp;</span><span className="bold">{this.state.LegendValue2}</span></div>
                                                    <div className="right">
                                                        <span>{this.state.LegendText2}</span>
                                                    </div>
                                                </div>
                                                <div className="same-transitions">
                                                    <div className="left"><span className="rectangle" style={{ background: tooltipColor3 }}>&nbsp;</span><span className="bold">{this.state.LegendValue3}</span></div>
                                                    <div className="right">
                                                        <span>{this.state.LegendText3}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="BarChart" scrolling="no" key="barchart_">
                                                <div className="myBarChart">
                                                    {
                                                        this.state.chartData1 != null ? this.state.chartData1.map((item, index) => {
                                                            return <Bar data={item} options={this.state.options} key={index} />
                                                        }) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                }
                            </div>
                        </div>
                        <div className="back">
                            <div className="second">
                                <div className="widget-iframe">
                                    <div className="myListChart myColumnChart col-lg-12" id={this.props.data.id}>
                                        {
                                            this.state.columnData != null ?
                                                <div>
                                                    <div className="mobile-scrolling-all tool-section list-data">
                                                        {
                                                            this.state.columnData.length > 1 ?
                                                                <div>

                                                                    {

                                                                        this.state.columnData != null ? this.state.columnData[0] ?
                                                                            <div className="tableheader" key={"TableHeader0"}>
                                                                                {this.state.columnData[0]["datasets"].map((e2, i2) => {
                                                                                    return (
                                                                                        <div key={'xyz' + i2}>{e2.data}</div>
                                                                                    )
                                                                                })}
                                                                            </div> : null : null
                                                                    }

                                                                    <div className="mainList">
                                                                        {


                                                                            (userlistobj.length > 6 ? userlistobj.splice((this.state.activePage - 1) * 6, 6) : userlistobj).map((obj, index) => {
                                                                                let headingtxt = this.state.columnData[0]["datasets"];
                                                                                return <div className="tablebody" key={"tablebody" + index}>
                                                                                    {obj["datasets"].map((e2, i2) => {
                                                                                        return (
                                                                                            <div key={'xyz' + i2}>{e2.data}</div>
                                                                                        )
                                                                                    })}
                                                                                </div>

                                                                            })
                                                                        }
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={this.state.activePage}
                                                                        itemsCountPerPage={6}
                                                                        totalItemsCount={paging}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />

                                                                </div>
                                                                : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                                        }
                                                    </div></div> : null

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MultiStackBar_List;
