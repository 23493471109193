import React, { Component } from 'react';
import { Pie, Column } from 'react-chartjs-2';
import Frame from '../widget/Frame';
import IFrame from '../widget/IFrame';
import moment, { relativeTimeRounding } from 'moment';
import FlipIcon from '../../images/flip.svg';
import InfoIcon from '../../images/info-icon.svg';
import BarChartIcon from '../../images/bar-chart-icon.svg';
import BarChartIconInactive from '../../images/bar-chart-inactive.svg';
import listingIcon from '../../images/listing-icon.svg';
import listingIconActive from '../../images/listing-icon-active.svg';
import loader from '../../images/loader.gif';
import exportIcon from '../../images/export.svg';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import { userService } from "../../_services";

class Column_Piechart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            PieChartData: null,
            columnData: [],
            options: {
                maintainAspectRatio: false,
            },
            IsDataLoaded: false,
            XlabelString: this.props.XlabelString,
            YlabelString: this.props.YlabelString,
            IFrameId: this.props._Index,
            LegendText: '',
            LegendValue: '',
            activePage: 1,
            noDataDisplay: '',
            userdata: JSON.parse(localStorage.getItem('user')),
            exportLoader: false,
            exportError: ''
        };
        this.handlechangea = this.handlechangea.bind(this);
    }
    handlechangea(e) {
        this.props.handlechangea(e);
    }
    handleFlip(event) {
        $(event.target).parent().next().toggleClass('flipped');
        $(event.target).toggleClass('active');
        $(event.target).parent().toggleClass('active');
    }
    ExportWidget = (event) => {
        try {
            var data = "";
            var tableData = [];
            for (var i = 0; i < this.state.columnData.length; i++) {
                var rowData = [];
                for (var j = 0; j < this.state.columnData[i]["datasets"].length; j++) {
                    rowData.push(this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                }
                tableData.push(rowData.join(","));
            }
            data += tableData.join("\n");
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', $(event.target).attr('widget-title') + '.csv');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) { }
    }
    componentDidUpdate(prevProp) {
        if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "Column_Pie") {
            this.fetchData_locally();
        }
    }
    componentDidMount() {
        $('body').click(function () {
            if ($(window).width() < 767) {
                $('.specialWidget').hide();
            }
        });
        if (this.props.data.type == "Column_Pie") {
            this.fetchData_locally();
        }
        // default toggle 
        $('#WDrugTransaction8 .flip_icon').parent().next().toggleClass('flipped');
        $('#WDrugTransaction8 .flip_icon').toggleClass('active');
        $('#WDrugTransaction8 .flip_icon').parent().toggleClass('active');

        $('#WDrugTransaction4 .flip_icon').parent().next().toggleClass('flipped');
        $('#WDrugTransaction4 .flip_icon').toggleClass('active');
        $('#WDrugTransaction4 .flip_icon').parent().toggleClass('active');

        $('#WDrugTransaction3 .flip_icon').parent().next().toggleClass('flipped');
        $('#WDrugTransaction3 .flip_icon').toggleClass('active');
        $('#WDrugTransaction3 .flip_icon').parent().toggleClass('active');
    }
    Randomcolor_Random() {
        return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    }
    Randomcolor(i) {
        //return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
        if (i % 10 == 0) {
            return '#39D890';
        } else if (i % 9 == 0) {
            return '#39D7D8';
        } else if (i % 8 == 0) {
            return '#FD775C';
        } else if (i % 7 == 0) {
            return '#80E166';
        } else if (i % 6 == 0) {
            return '#6AC7F1';
        } else if (i % 5 == 0) {
            return '#0282FB'
        } else if (i % 4 == 0) {
            return '#CB62FF'
        } else if (i % 3 == 0) {
            return '#7F84E8'
        } else if (i % 2 == 0) {
            return '#FCE048'
        } else {
            return '#FFBA10'
        }
    }
    fetchData_locally() {
        let data_available = '';
        this.setState({
            IsDataLoaded: false,
            noDataDisplay: 'Loading. Please wait.'
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                //--------------------------------Pie Chart Data Handling Start Here-------------------------
                try {
                    this.setState({ LegendText: apidata[0].legend[0].legendText, LegendValue: apidata[0].legend[0].legendValue });

                    // data not available
                    data_available = apidata[0].pieDataSet[0].datasets[0].data.length <= 1 ? 'No Data Available' : '';
                    this.setState({ activePage: 1 });

                }
                catch (error) {

                }
                try {
                    var St_bgcolor = [];
                    var St_hovercolor = [];
                    St_bgcolor = apidata[0].pieDataSet[0].datasets[0].backgroundColor;
                    St_hovercolor = apidata[0].pieDataSet[0].datasets[0].hoverBackgroundColor;
                    try {
                        const bg_color_array = apidata[0].pieDataSet[0].datasets[0].backgroundColor;
                        var color_in_string = bg_color_array.toLocaleString();
                        var temp_col1 = color_in_string.replaceAll('#000000', '#000000');
                        var temp_col2 = temp_col1.replaceAll('#FF8B6C', '#F1857F');
                        var temp_col3 = temp_col2.replaceAll('#44B672', '#EE934A');
                        var temp_col4 = temp_col3.replaceAll('#3CB26D', '#EE934A');
                        var temp_col5 = temp_col4.replaceAll('#A6DBBD', '#9fd4fc');
                        St_bgcolor = temp_col5.split(',');

                        const hv_color_array = apidata[0].pieDataSet[0].datasets[0].hoverBackgroundColor;
                        var hv_color_in_string = hv_color_array.toLocaleString();
                        var hv_temp_col1 = hv_color_in_string.replaceAll('#000000', '#000000');
                        var hv_temp_col2 = hv_temp_col1.replaceAll('#FF8B6C', '#F1857F');
                        var hv_temp_col3 = hv_temp_col2.replaceAll('#44B672', '#EE934A');
                        var hv_temp_col4 = hv_temp_col3.replaceAll('#3CB26D', '#EE934A');
                        var hv_temp_col5 = hv_temp_col4.replaceAll('#A6DBBD', '#9fd4fc');
                        St_hovercolor = hv_temp_col5.split(',');
                    } catch (err) {
                    }
                    var dataset = new Array();
                    dataset.push({ labels: apidata[0].pieDataSet[0].labels, datasets: [{ data: apidata[0].pieDataSet[0].datasets[0].data, backgroundColor: St_bgcolor, hoverBackgroundColor: St_hovercolor, hoverBorderColor: this.props.data.DataType }] });
                    this.setState({
                        ...this.state,
                        IsDataLoaded: true,
                        noDataDisplay: data_available,
                        PieChartData: dataset[0],
                        options: {
                            onHover: function (e, el) {
                                $("canvas").css("cursor", el[0] ? "pointer" : "default");
                            },
                            plugins: {
                                tooltip: {

                                    // Disable the on-canvas tooltip
                                    enabled: false,
                                    external: function (context) {

                                        // Tooltip Element
                                        let tooltipEl = document.getElementById('chartjs-tooltip');

                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table style="background: #fff; font-family: Urbanist, sans-serif; margin-left: calc(-50% - 4px); position: relative; z-index: 99999; font-weight: 600; border: 2px solid #B6ACA2; border-radius: 6px; padding: 5px 12px; line-height: 28px; color: #000000; font-size: 14px; text-align: left; width: 100%; display: block;"></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        const tooltipModel = context.tooltip;
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            tooltipEl.style.display = 'none';
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }

                                        // Set Text
                                        if (tooltipModel.body) {
                                            const titleLines = tooltipModel.title || [];
                                            const bodyLines = tooltipModel.body.map(getBody);
                                            let innerHtml = '<thead>';

                                            titleLines.forEach(function (title) {
                                                innerHtml += '<tr><th style="font-size: 14px; font-family: Urbanist, sans-serif; line-height: 18px; padding: 0px;display: block; font-weight: normal;">' + title + '</th></tr>';
                                            });
                                            innerHtml += '</thead><tbody>';
                                            bodyLines.forEach(function (body, i) {
                                                const colors = tooltipModel.labelColors[i];
                                                const span = '<span style="position: absolute; bottom: -7px; left: 50%; margin-left: -4px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                                innerHtml += '<tr><td style="white-space: nowrap; display: block;">' + span + body + '</td></tr>';

                                                // set data hover
                                                let pie_id = context.chart.canvas.getAttribute('id');
                                                const pie_chart = document.getElementById(pie_id);
                                                pie_chart.setAttribute("data-hover", body);
                                            });
                                            innerHtml += '</tbody>';

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }
                                        const position = context.chart.canvas.getBoundingClientRect();
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.display = 'block';
                                        tooltipEl.style.position = 'absolute';
                                        tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + 'px';
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + -51 + 'px';
                                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                },
                                legend: {
                                    labels: {
                                        font: {
                                            color: '#827c78'
                                        }
                                    },
                                    display: false
                                },
                                elements: {
                                    arc: {
                                        borderWidth: 1
                                    }
                                },
                                responsive: true
                            }
                        },
                    });
                }
                catch (error) {
                }
                //--------------------------------List Data Handling Start Here-------------------------
                try {
                    this.setState({
                        columnData: apidata[0].listDataSet
                    });
                }
                catch (error) { }
                //return dataset;
            });
    }
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            return data;
        });
    }
    handleBarList(event) {
        if ($(window).width() < 767) {
            event.stopPropagation();
            $(event.target).parents('.custom-tooltip').parent().siblings('.flip-bg').toggleClass('flipped');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').toggleClass('active');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').parent().toggleClass('active');
            $(event.target).parents('.flip-section').children('.flip-icons').hide();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').hide();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').hide();
        }
    }
    mobileMenu(event) {
        $('.specialWidget').show();
        $('.flip-icons').hide();
        $('.export_icon').hide();
        $('.mobileExportLabel').hide();
        if ($(event.target).parents('.flip-section').children('.flip-icons').hasClass('active')) {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_bar_icon').show();
            $('.mobileBarLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobileListLabel').hide();
            $('.mobile_list_icon').hide();
        } else {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_list_icon').show();
            $('.mobileListLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobileBarLabel').hide();
            $('.mobile_bar_icon').hide();
        }
    }
    HandlePieSectionClick = e => {
        var ctx = document.getElementById('chartjs-render-monitor').getContext('2d');
    }
    GetTagTypeFullName(stext) {
        if (stext == 'state name') {
            return 'PhState';
        }
        else if (stext == "drug name") {
            return "DrugName";
        }
        else if (stext == "city name") {
            return "PhCity";
        }
        else if (stext == "wholesaler name") {
            return "WholesalerName";
        }

        else {
            return stext;
        }
    }
    handleTextClick = (e) => {
        let stext = $(e.target).attr('data-id');
        stext = stext.toLowerCase();
        if ((stext == "state name" || stext == "drug name" || stext == "city name" || stext == "wholesaler name") && $(e.target).attr('data-text') != "Total") {
            var nodevalue = $(e.target).attr('data-text');
            var nodetype = this.GetTagTypeFullName(stext);
            if (stext == "city name") {
                nodevalue = nodevalue.split(',')[0];
            }
            if (nodevalue != "" && nodevalue != null && nodevalue != undefined && nodevalue != "null") {
                this.ProcessdashboardUpdate(nodetype, nodevalue);
            }
        }
    }
    ProcessdashboardUpdate(nodetype, nodevalue) {
        var Created_Advance_Filter = JSON.parse(localStorage.getItem('Advance_Filter'));
        var arr = [];
        if (Created_Advance_Filter != null && Created_Advance_Filter != '' && Created_Advance_Filter != undefined && Created_Advance_Filter != "null" && Created_Advance_Filter != "{}") {
            var IsMatched = false;
            Object.keys(Created_Advance_Filter).forEach(key => arr.push({ Name: key, Value: Created_Advance_Filter[key] }))
            for (var ij = 0; ij < arr.length; ij++) {
                if (arr[ij].Name == nodetype) {
                    IsMatched = true;
                    let checkavail = 0;
                    Created_Advance_Filter[nodetype].map((e1, i1) => {
                        if (e1.toLowerCase() == nodevalue.toLowerCase()) {
                            checkavail = checkavail + 1;
                        }
                    });
                    if (checkavail == 0) {
                        Created_Advance_Filter[nodetype].push(nodevalue);
                        localStorage.setItem('Advance_Filter', JSON.stringify(Created_Advance_Filter));
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                        this.handlechangea();

                    }
                    break;
                }
                else {
                    continue;
                }
            }
            if (IsMatched == false) {
                try {
                    var JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                    let TempJson = JSON.stringify(Created_Advance_Filter).replace('{', '').replace('}', '');
                    TempJson = '{' + TempJson + ',' + JsonData + '}';
                    localStorage.setItem('Advance_Filter', TempJson);
                    let newDate = new Date();
                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', TempJson);
                    this.handlechangea();

                }
                catch (error) {
                }
            }

        }
        else {

            var JsonData = '';
            try {
                JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                JsonData = '{' + "\"" + "dataset" + "\"" + ': {' + JsonData + '}}';
                localStorage.setItem('Advance_Filter', JSON.stringify(JSON.parse(JsonData)["dataset"]));
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                this.handlechangea();

            }
            catch (error) {
            }
        }
    }
    HandleClickOnSlice = (e) => {
        try {
            $('#chartjs-tooltip').css('display','none');
            let SliceText = e.target.getAttribute('data-hover').split(':')[0].split(',')[0];
            let SliceType = e.target.getAttribute('pie_role');
            if (SliceType == 'WCityTransaction') {
                SliceType = 'PhCity';
            } else if (SliceType == 'WStateTransaction' || SliceType == 'DStateTransaction') {
                SliceType = 'PhState';
            } else if (SliceType == 'WDrugTransaction') {
                SliceType = 'DrugName';
            }
            this.ProcessdashboardUpdate(SliceType, SliceText);
        }
        catch (error) {
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }
    handlePie(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().next().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }
    handleList(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().prev().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }
    titleHover = e => {
        if (this.isEllipsisActive(e.target)) {
            e.target.parentElement.classList.add("hover-on");
        }
    }
    isEllipsisActive = (element) => {
        return element.offsetWidth < element.scrollWidth;
    }
    ApiExportWidget = (event) => {
        this.setState({ exportLoader: true, exportError: 'Your request is in process' });
        try {
            var startdate = new Date();
            startdate.setDate(startdate.getDate() - 30);
            var enddate = new Date();
            if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
                startdate = localStorage.getItem('User_Start_Date');
            }
            if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
                enddate = localStorage.getItem('User_End_Date');
            }
            let csv_name = 'Active_Inactive_Brokers';
            let wid_title = $(event.target).attr('widget-title');
            csv_name = wid_title == "Active/Inactive Brokers by Transactions" ? "Active_Inactive_Brokers_By_Transactions" : "Active_Inactive_Brokers_By_Enrollemnts";
            var AnalyticFilterJson = '', FilterJson = '';
            AnalyticFilterJson = JSON.parse(localStorage.getItem('Analytic_Filter'))
            FilterJson = JSON.stringify(AnalyticFilterJson);
            userService.Export_reps_bytransaction(this.state.userdata.userrole, this.state.userdata.email, startdate, enddate, FilterJson, wid_title).then(
                apidata => {
                    try {
                        var data = "";
                        var tableData = [];
                        for (var i = 0; i < apidata.length; i++) {
                            var rowData = [];
                            rowData.push(apidata[i]["username"].replace(',', '*').split(',').join(''));
                            rowData.push(apidata[i]["email"].replace(',', '*').split(',').join(''));
                            rowData.push(apidata[i]["phone"].replace(',', '*').split(',').join(''));
                            rowData.push(apidata[i]["status"].replace(',', '*').split(',').join(''));
                            tableData.push(rowData.join(","));
                        }
                        data += tableData.join("\n");
                        if (window.navigator.msSaveOrOpenBlob) {
                            // IE11
                            window.navigator.msSaveOrOpenBlob(new Blob([data]), csv_name + ".csv");
                        } else {
                            $(document.body).append('<a id="download-link" download="' + csv_name + ".csv" + '" href=' + URL.createObjectURL(new Blob([data], { type: "text/csv" })) + '/>');
                        }
                        $('#download-link')[0].click();
                        $('#download-link').remove();
                        this.setState({ exportError: '', exportLoader: false });
                    }
                    catch (error) {
                        this.setState({ exportError: 'Error! Try again.', exportLoader: false });
                        setTimeout(() => {
                            this.setState({ exportError: '' });
                        }, 5000);
                    }
                }
            );
        }
        catch (error) {
            this.setState({ exportError: 'Error! Try again.', exportLoader: false });
            setTimeout(() => {
                this.setState({ exportError: '' });
            }, 5000);
        }
    }
    ApiExportWidgetMobile = (event) => {
        $('.flip-icons').hide();
        $('.export_icon').hide();
        $('.mobileExportLabel').hide();
        if ($(event.target).parents('.flip-section').children('.flip-icons').hasClass('active')) {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.bar_chart_icon').show();
            $('.bar_chart_icon.inactive').hide();
            $('.mobileBarLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.activeicon').hide();
            $('.mobileListLabel').hide();
        } else {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.activeicon').show();
            $('.mobileListLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.bar_chart_icon').hide();
            $('.mobileBarLabel').hide();
        }
        this.setState({ exportLoader: true, exportError: 'Your request is in process' });
        setTimeout(() => {
            this.setState({ exportError: '' })
        }, 5000);
    }
    render() {
        const tooltipColor = this.props.data.ColorCode;
        const userlistobj = JSON.parse(JSON.stringify(this.state.columnData));
        userlistobj.splice(0, 1);
        let paging = 0;
        paging = userlistobj.length % 6 != 0 ? userlistobj.length + 6 - userlistobj.length % 6 : userlistobj.length;
        return (
            <div className="bar">
                {this.state.IsDataLoaded ? '' : (<div className="loader-section"><div className="theme-loader"></div></div>)}
                <div className="flip-section">
                    <div className="widget-head mobile-position">
                        <h3>
                            <a>{this.props.data.id === 'Day_Supply_30_90' || this.props.data.id === 'Member_Age' ? '' : <span>{this.state.LegendValue}&nbsp;</span> }<span data-title={this.props.data.title} className="transition-title"><label onMouseOver={this.titleHover}>{this.props.data.title}</label><p>{this.props.data.Subtitle}</p></span></a>
                        </h3>
                    </div>
                    {this.state.exportError && <div className={'alert-error export-error'}>{this.state.exportError}</div>}
                    <a href="javascript:void('0')" widget-title={this.props.data.title} widget-id={this.props.data.id} className="onlyList custom-tooltip" id={this.props.data.title}><img onClick={this.ExportWidget} widget-title={this.props.data.title} widget-id={this.props.data.id} className="export_icon" src={exportIcon} alt="Export Icon" /><span className="hover-tooltip down-report">Download report</span></a>
                    <label className="mobileExportLabel" widget-title={this.props.data.title} widget-id={this.props.data.id} onClick={this.ExportWidget} >Export</label>
                    <a className="mobileListIcon" href="javascript:void('0')" onClick={this.mobileMenu}>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                    </a>
                    <div className="flip-icons">
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img className="bar_chart_icon" src={BarChartIcon} alt="barcharticon" />
                            <img onClick={this.handleBarList} className="mobile_bar_icon" src={BarChartIcon} alt="barcharticon" />
                            <label onClick={this.handleBarList} className="mobileBarLabel">Pie</label>
                            <img className="bar_chart_icon inactive" onClick={this.handlePie} src={BarChartIconInactive} alt="barcharticoninactive" />
                            <span className="hover-tooltip bar">Pie</span></a>
                        <a href="javascript:void('0')" onClick={this.handleFlip} className="flip_icon">Flip</a>
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img className="listing-icon" onClick={this.handleList} src={listingIcon} alt="listingicon" />
                            <img className="listing-icon activeicon" src={listingIconActive} alt="listcharticonactive" />
                            <img onClick={this.handleBarList} className="mobile_list_icon" src={listingIconActive} alt="listcharticonactive" />
                            <label onClick={this.handleBarList} className="mobileListLabel">List</label>
                            <span className="hover-tooltip list">List</span></a>
                    </div>
                    <div className="flip-bg" id={this.state.IFrameId + "_flip_bg_frame"}>
                        <div className="front">
                            <div className="PieChart first">
                                {
                                    this.state.PieChartData != null && this.state.PieChartData.labels.length > 0 ?
                                        <div id={this.state.IFrameId + "Pie"} scrolling="no" key={"graph-frame" + this.state.IFrameId + "pie"}>
                                            <div className="PieChart">
                                                {
                                                    this.state.PieChartData != null ? <Pie id={"pieChart" + this.state.IFrameId} pie_role={this.props.data.id} data={this.state.PieChartData} options={this.state.options} onClick={this.HandleClickOnSlice} /> : null
                                                }
                                            </div>
                                        </div> : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                }

                            </div>
                        </div>
                        <div className="back">
                            <div className="second">
                                <div className="widget-iframe">
                                    <div className="myListChart myColumnChart col-lg-12" id={this.props.data.id}>
                                        {
                                            this.state.columnData != null ?
                                                <div>
                                                    <div className="mobile-scrolling-all tool-section list-data Pie-data">
                                                        {
                                                            this.state.columnData.length > 1 ?
                                                                <div>

                                                                    {

                                                                        this.state.columnData != null ? this.state.columnData[0] ?
                                                                            <div className="tableheader" key={"TableHeader0"}>
                                                                                {this.state.columnData[0]["datasets"].map((e2, i2) => {
                                                                                    return (
                                                                                        <div key={'xyz' + i2}>{e2.data}</div>
                                                                                    )
                                                                                })}
                                                                            </div> : null : null
                                                                    }

                                                                    <div className="mainList">
                                                                        {
                                                                            (userlistobj.length > 6 ? userlistobj.splice((this.state.activePage - 1) * 6, 6) : userlistobj).map((obj, index) => {
                                                                                let headingtxt = this.state.columnData[0]["datasets"];
                                                                                let totalList = obj["datasets"].filter((item) => item.data == 'Total');
                                                                                return <div className={totalList.length > 0 ? "tablebody last-list" : "tablebody"} key={"tablebody" + index}>
                                                                                    {obj["datasets"].map((e2, i2) => {
                                                                                        return (
                                                                                            <div data-id={headingtxt[i2].data} data-text={e2.data} key={'xyz' + i2} onClick={this.handleTextClick}>{e2.data}</div>
                                                                                        )
                                                                                    })}
                                                                                </div>

                                                                            })

                                                                        }
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={this.state.activePage}
                                                                        itemsCountPerPage={6}
                                                                        totalItemsCount={paging}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />

                                                                </div>
                                                                : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                                        }
                                                    </div>

                                                </div> : null

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }


}
export default Column_Piechart;