import React, { Component } from "react";
import Signup_header from "../Register/signup_header";
import { userService } from "../../_services";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { Redirect } from "react-router-dom";
//import UpdatedTerms from "../Register/UpdatedTerms";Commented because not in use

class CompliancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: ''
        };
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.Get_User_Summary_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
    }
    Get_User_Summary_Details(UserId, UserName) {
        userService.GetUserSummaryDetail(UserId, UserName)
            .then(
                apidata => {
                    localStorage.setItem('hacker_user_detail', window.btoa(apidata[0].shortName + ':' + apidata[0].fullName));
                    let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
                    if (apidata.length > 0) {
                        if (apidata[0].pqKey !== "" && apidata[0].pqKey !== null && apidata[0].pqKey != undefined)
                            this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: apidata[0].pqKey, Iskeyvalue: true });
                        else
                            this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: "", Iskeyvalue: false });
                    }
                }
            );
    }

    render() {
        let showemail = userService.getemail();
        localStorage.setItem('pagename', 'compliance');

        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <>
                {
                    !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? null :
                    <div className="container_signup">
                        <Signup_header />
                    </div>
                }
                <div>
                </div>
                <div className={!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? "Dashboard_Area terms_box compliance" :"terms_box compliance"}>
                    {
                        !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                            <Left_Sidebar Activemenu="7" account_info={this.state.account_info} fullName={this.state.fullName} />
                        : null
                    }
                    <div className={!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? "contentarea" :"container_signup"}>
                        {
                            !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                : null
                        }
                        <div className="content_sec">
                            {
                                !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                                    <h2 className="differ">Compliance Statement</h2>
                                    : <h1>Compliance Statement</h1>
                            }

                        <p>NuLifeSpan Rx ("we" or "us") is committed to legal and ethical business practices and it takes compliance with healthcare laws very seriously.  But it doesn’t stop with us.  Compliance is everyone’s responsibility!  To this end, we require all personnel, vendors, contractors, and partners to review the following summary of key compliance issues related to our business offerings:</p>
                        <ul>
                            <li>NuLifeSpan Rx expects you to conduct yourself in an ethical and legal manner.  It’s about doing the right thing!
                            <ul>
                                <li>Act fairly and honestly</li>
                                <li>Adhere to high ethical standards in all that you do</li>
                                <li>Comply with all applicable laws and regulations, and</li>
                                <li>Report suspected violations.
                                        <ul>
                                            <li>You can email <a href={"mailto:" + showemail} >{showemail}  </a></li>
                                            <li>If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:
                                            <ul>
                                                    <li>Website:<a href="http://www.lighthouse-services.com/rxhacker">www.lighthouse-services.com/rxhacker</a></li>
                                                    <li>Toll-Free Telephone:
                                                    <ul>
                                                        <li>English speaking:<a href="tel: 833-222-2698"> 833-222-2698</a></li>
                                                        <li>Spanish  speaking:<a href="tel: 800-216-1288"> 800-216-1288</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Failure to follow all applicable healthcare laws and regulations can lead to serious consequences, including:
                            <ul>
                                    <li>Contract termination</li>
                                    <li>Criminal penalties</li>
                                    <li>Exclusion from participating in all Federal healthcare programs</li>
                                    <li>Civil monetary penalties</li>
                        </ul>
                    </li>

                            </ul>
                        <h2>Prescription Drug Discount Cards and Programs</h2>
                        <p>
                            Prescription discount card services are highly regulated by state and federal governments, and our compliance with these laws is not only monitored by government regulators, but also by our customers, competitors, and the public at large.  In other words, someone is always watching.</p>
                        <p>NuLifeSpan Rx expects its employees, vendors, contractors, and partners to comply with all laws and regulations governing prescription drug discount cards.  This includes providing clear and consistent information to discount card members, with particular regard to the following points:</p>
                            <ul>
                            <li>NuLifeSpan Rx offers a prescription drug discount card, either branded or white-labeled with a Marketer’s own branding, and in the form of a physical card, a mobile app, a downloadable card or other mutually agreed format (the “Program”).  The Program is NOT a health insurance policy or a substitute for health insurance.</li>
                            <li>The Program allows members to obtain discounts on prescription drugs and/or pharmaceutical products purchased through participating pharmacies.  The member is responsible for all payments to pharmacies from whom he or she receives a prescription discount.  NuLifeSpan Rx does not make payments to any pharmacy, pharmacy employee, or healthcare provider.</li>
                            <li>The price paid to the pharmacy is always the lesser of the NuLifeSpan Rx discounted price or the pharmacy’s retail price.  If the pharmacy’s retail price is less than the NuLifeSpan Rx discounted price, there is no discount.  Discounts are available only at pharmacies that participate with NuLifeSpan Rx.  Participating pharmacies and all NuLifeSpan Rx pricing are subject to change without notice.</li>
                            <li>The Program may not be used with other prescription drug discount cards or for prescriptions paid through a health plan or pharmacy benefit plan.  If a member has health insurance or any other funded benefit such as Medicaid or Medicare, he or she can ONLY use the Program for certain prescriptions that are NOT covered by their plan.</li>
                            <li>NuLifeSpan Rx does not offer insurance of any kind.</li>
                            <li>NuLifeSpan Rx does not provide medical, dental, or other health-related services.</li>
                            <li>The Program is offered to consumers free of charge.</li>
                            <li>NuLifeSpan Rx does not make payments to any pharmacy, pharmacy employee, or healthcare provider.</li>
                        </ul>
                        <h2>Healthcare Fraud, Waste, and Abuse</h2>
                        <p>The federal government and many state governments have adopted laws that prohibit conduct that can cause fraud, waste, and abuse in healthcare programs.  Whether certain laws apply to a business arrangement or a product depends on the facts, by NuLifeSpan Rx requires and expects its vendors, contractors, and partners to comply with these laws, consider the implications of them in all business dealings, and engage in ethical busines practices.</p>
                        <p><strong>Fraud</strong></p>
                        <ul>
                            <li>Is an intentional act of deception, misrepresentation, or concealment in order to gain something of value</li>
                            <li>Often involves billing for services that were never rendered or billing for services at a higher rate than is actually justified.</li>
                        </ul>
                        <p><strong>Waste</strong></p>
                        <ul>
                            <li>Is over-utilization of services and misuse of resources</li>
                            <li>Is generally not considered to be caused by criminally negligent actions but rather by the misuse of resources</li>
                        </ul>
                        <p><strong>Abuse</strong></p>
                        <ul>
                            <li>Is the excessive or improper use of healthcare services or actions that are inconsistent with acceptable business and/or medical practice</li>
                            <li>Refers to incidents that, although not considered fraudulent, may directly or indirectly cause financial loss.</li>
                        </ul>
                        <h2>Laws Targeting Healthcare Fraud, Waste, and Abuse</h2>
                        <p>To ensure appropriate business relationships, NuLifeSpan Rx requires its vendors, contractors, and partners to specifically refrain from the type of conduct prohibited by the following federal laws:</p>
                        <ul>
                        <li>Anti-Kickback Statute
                        <ul>
                                    <li>The Anti-Kickback Statute makes it’s a criminal offense to knowingly offer or receive anything of value (e.g., money) to induce referrals of items or services payable by a federal healthcare program, such as Medicare or Medicaid.  In some industries, it is acceptable to reward those who refer business to you.  However, in the Federal healthcare programs, paying for referrals is a crime.</li>
                                    <li>A violation of the Anti-Kickback Statute can occur if any one purpose of the arrangement is improper, even if other purposes exist that do not implicate the Anti-Kickback Statute.</li>
                                    <li>Violations of the Anti-Kickback Statute may be criminal or civil and are punishable by imprisonment, substantial fines, or both.  Most states have adopted laws that mirror the Anti-Kickback Statute and apply outside of federal healthcare programs.</li>
                                </ul>
                            </li>
                            <li>
                                Civil Monetary Penalties (CMP) Law
                                <ul>
                                    <li>Among other things, the CMP Law imposes civil fines on any person who offers or provides anything of value (e.g., money) to a Medicare or Medicaid beneficiary that is likely to influence the beneficiary’s selection of a particular provider or supplier for any item or service reimbursable by Medicare or Medicaid.  Many states have adopted laws that mirror the CMP Law and apply outside of federal healthcare programs.</li>
                                </ul>
                            </li>
                            <li>Healthcare Fraud Statute
                            <ul>
                                    <li>The Healthcare Fraud Statute makes it a criminal offense to knowingly and willfully execute a scheme to defraud a healthcare benefit program.  Healthcare fraud is punishable by imprisonment up to 10 years.  It is also subject to criminal fines of up to $250,000.</li>
                                    <li>Conviction under the statute does not require proof the violator had knowledge of the law or specific intent to violate the law.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>How do you prevent fraud, waste and abuse?</h2>
                        <ul>
                            <li>Look for suspicious activity</li>
                            <li>Conduct yourself in an ethical manner</li>
                            <li>Ensure accurate and timely data and billing</li>
                            <li>Ensure coordination with other payers</li>
                            <li>Know policies and procedures, standards of conduct, laws, regulations, and related guidance</li>
                            <li>Verify all received information</li>
                            <li>If you have questions or are concerned about potential compliance issues, reach out to our compliance team (<a href={"mailto:" + showemail} > {showemail}</a>).
                            <ul>
                                    <li>If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:
                                            <ul>
                                            <li>Website:<a href="http://www.lighthouse-services.com/rxhacker">www.lighthouse-services.com/rxhacker</a></li>
                                            <li>Toll-Free Telephone:
                                                    <ul>
                                                    <li>English speaking:<a href="tel: 833-222-2698"> 833-222-2698</a></li>
                                                    <li>Spanish  speaking:<a href="tel: 800-216-1288"> 800-216-1288</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2>So . . . what CAN’T I do?</h2>
                        <p><strong>DON’T</strong> offer money, gifts, kickbacks, bribes or anything else of monetary value to doctors, nurses, pharmacists, pharmacy techs or interns, or other healthcare providers or pharmacy employees to promote NuLifeSpan Rx, the Program or any white-labeled service of NuLifeSpan Rx (or otherwise) or to enroll members in the Program or the NuLifeSpan Rx Program.</p>
                        <p><strong>DON’T</strong> use ANY marketing materials that have not be pre-approved by NuLifeSpan Rx.  That means web sites, physical cards, emails, faxes, flyers, text messages, apps or any other kind of marketing or solicitation materials you can think of.</p>
                        <p><strong>DON’T</strong> use the trademarks, names, or logos of other businesses in your marketing materials unless you have the right (a license) to do so and unless your marketing materials have been approved by NuLifeSpan Rx. </p>
                        <p><strong>DON’T</strong> imply or state that the Program is insurance coverage or a replacement for insurance.</p>
                        <p><strong>DON’T</strong> charge a fee for participation in the Program.  The Program is free to consumers. </p>
                        <p><strong>DON’T</strong> make any statements about the NuLifeSpan Rx Program that we have not expressly approved.</p>
                        <p><strong>DON’T</strong> ignore compliance or think “Someone else is handling that.”  Compliance is everyone’s responsibility, including yours.</p>
                        <h2>So . . . what CAN I do?</h2>
                        <p><strong>DO</strong> engage in open and honest marketing efforts.  Explain the value of doing business with the Program to offer prescription discounts.</p>



                        <p><strong>DO</strong> communicate clearly regarding prescription drug discount cards and how the NuLifeSpan Rx program works.</p>



                      <p><strong>DO</strong> report to NuLifeSpan Rx any instance in which you become aware of a pharmacist demanding or receiving money or anything of monetary value for agreeing to promote NuLifeSpan Rx, the Program or any competing managed pharmacy program.</p>



                        <p><strong>DO</strong> take compliance seriously.  Conduct yourself in an ethical and legal manner.  Act fairly and honestly in all business dealings. </p>



                        <p><strong>DO</strong> act in a manner that does not conflict with, contradict, or otherwise misrepresent Honest Discount’s mission and vision.</p>



                        <p><strong>DO</strong> ask questions!  If you have questions or concerns, reach out to {showemail}. </p>
                        <h2>Data Use, Information Security & Privacy</h2>
                        <p>NuLifeSpan Rx expects its vendors, contractors, and partners to protect all data and information related to NuLifeSpan Rx’s business; and to comply with all applicable laws pertaining to the confidentiality, privacy, data security, and data use as set forth in our agreements. </p>
                        <h2>NuLifeSpan Rx Oversight</h2>
                        <p>NuLifeSpan Rx may conduct oversight of the services you perform to ensure that you are complying with the requirements explained in this Compliance Standards and applicable laws, rules, and regulations.  NuLifeSpan Rx may periodically ask you to complete a self-assessment, survey, and/or attest to applicable policy, procedure and compliance requirements.  NuLifeSpan Rx may also perform on-site audits or inspections of facilities, procedures, and records that relate to the services provided under your contractual agreement.</p>
                        <h2>Reporting Compliance Concerns</h2>
                        <p>Reporting compliance concerns is everyone’s responsibility.  You play a vital part in preventing, detecting, and reporting potential noncompliance.  Please report any suspected cases of noncompliance without fear of retaliation or retribution for reports made in good faith:</p>
                        <ul>
                            <li>Contact our Compliance Team: {showemail}</li>
                            <li>If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:
                                            <ul>
                                    <li>Website:<a href="http://www.lighthouse-services.com/rxhacker">www.lighthouse-services.com/rxhacker</a></li>
                                    <li>Toll-Free Telephone:
                                                    <ul>
                                            <li>English speaking:<a href="tel: 833-222-2698"> 833-222-2698</a></li>
                                            <li>Spanish  speaking:<a href="tel: 800-216-1288"> 800-216-1288</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2>I understand that any violation of these Standards is a violation of the terms and conditions of my Agreement with NuLifeSpan Rx, and could result in the enforcement of legal remedies against me, including, but not limited to, termination of my Agreement with NuLifeSpan Rx and the forfeiture of any amounts due thereunder.  I further understand that a violation of these Standards may also subject me to federal and state civil and/or criminal culpability.</h2>
                        <h2>I also understand that no statement contained in these Standards creates any guarantee of continued contract or employment, or creates any obligations, contractual or otherwise, on the part of NuLifeSpan Rx.  These Standards are not promissory and do not set terms or conditions of employment or create an employment contract.</h2>
                        <h2>Compliance Attestation</h2>
                        <p>NuLifeSpan Rx LLC (“NuLifeSpan Rx”) and the undersigned Marketer have a mutual desire to maintain effective compliance and ethics programs in support of the pharmacies who administer the prescription discount program provided by NuLifeSpan Rx LLC (the “NuLifeSpan Rx Program”) and the patients who use these programs.  As evidence of this commitment, NuLifeSpan Rx seeks to confirm the Marketer’s efforts as a compliment to the contractual obligations between the parties (which remain in full force and effect and unmodified hereby).</p>
                        <p>For purposes of this attestation, Marketer represents and warrants the following:</p>
                        <ul>
                            <li>The Marketer, including any person or entity with whom the Marketer has contracted in connection with the NuLifeSpan Rx Program (its “Contractors”), adheres to all applicable local, state, and federal rules and regulations, including, without limitation, those applicable to the NuLifeSpan Rx Program and their distribution thereof.</li>
                            <li>The Marketer will monitor its operations and those of all of its Contractors for any activities which may create conflict, disruption, or improper inducement or incentives that are inconsistent with the purpose and operations of the Program.</li>
                            <li>The Marketer will monitor its operations and those of its Contractors for any activities which may interfere with or jeopardize any relationship between NuLifeSpan Rx and any third parties, including eligible pharmacies in the NuLifeSpan Rx Program.</li>
                            <li>Where the Marketer enters into agreements with a pharmacy that utilize the NuLifeSpan Rx Program but creates other financial or reimbursement arrangements, the Marketer shall immediately disclose such an arrangement to NuLifeSpan Rx.</li>
                            <li>The Marketer has a mechanism for receiving concerns, complaints or reports of misuse and a process for escalating issues to NuLifeSpan Rx where there is any impact on any claims or with respect to any pharmacy.</li>
                            <li>The Marketer has incorporated standards of this attestation into is Contractor agreements in a manner which represents substantially similar obligations by the Contractor.</li>
                            <li>Where the Marketer sanctions or otherwise alters its relationship with a Contractor due to compliance or ethics concerns, the Marketer shall immediately notify NuLifeSpan Rx to the extent that such a relationship relates to or impacts the NuLifeSpan Rx Program in any way and where such action is not deemed confidential.</li>
                            <li>Where the Marketer is being sanctioned by any pharmacy or otherwise prohibited from conducting operations at any pharmacy due to compliance or ethics concerns, the Marketer shall notify NuLifeSpan Rx immediately.</li>
                        </ul>
                        <p>The Marketer attests that it is upholding these compliance and ethics standards and that the Marketer has disclosed applicable impacts to NuLifeSpan Rx.</p>
                    </div>
                    </div>
                </div>
                {
                    !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? null :
                    <div className="signup_footer"><div><a href="/terms">Terms</a> | <a href="/compliance">Compliance</a></div><div>Copyright &copy; {new Date().getFullYear()} NuLifeSpan Rx. All rights reserved.</div></div>
                }
            </> : <Redirect to="/page-not-found" />
        );
    }
}
export default CompliancePage;
